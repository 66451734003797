import { fromJS } from 'immutable'
import { UserOutlined, InfoOutlined, MessageOutlined,
    DatabaseOutlined, InfoCircleOutlined, SettingOutlined,
    BlockOutlined,
} from '@ant-design/icons';

import * as actionTypes from './actionTypes'

const defaultState = fromJS({
    navbar_menu: [
        {
          label: '用户统计信息',
          key: 'user_info',
          icon: <UserOutlined />,
        },
        {
          label: '对话统计信息',
          key: 'user_conversation_count',
          icon: <InfoOutlined />,
        },
        {
            label: '个人对话信息',
            key: 'personal_conversation',
            icon: <MessageOutlined />,
        },
        {
            label: '数据管理',
            key: 'data_manage',
            icon: <DatabaseOutlined />,
        },
        {
            label: '自建知识库配置项',
            key: 'model_manage',
            icon: <BlockOutlined />,
        },
        {
            label: '版本更新信息',
            key: 'version_update',
            icon: <InfoCircleOutlined />,
        },
        {
            label: '权限管理',
            key: 'permission_management',
            icon: <SettingOutlined />,
            children: [
                {
                  key: 'user_permission_management',
                  label: '用户权限管理',
                },
                {
                  key: 'group_permission_management',
                  label: '群组权限管理',
                },
            ],
        },
        {
            label: '用户画像管理',
            key: 'categorize_management',
            icon: <SettingOutlined />,
            children: [
                {
                    key: 'categorize_generate_management',
                    label: '生成用户画像数据',
                },
                {
                    key: 'categorize_setting_management',
                    label: '分类项的管理',
                },
                {
                  key: 'categorize_user_management',
                  label: '单独用户画像',
                },
                {
                  key: 'categorize_all_management',
                  label: '总体用户画像',
                },
            ],
        },
    ],
    active_navbar: 'user_info',
    user_statistics_data: [
        // {
        //     last_login: "2024-07-12 14:36:22",
        //     gender: "男",
        //     vip_level: 1,
        //     occupation: "学生",
        //     city: "上海",
        //     vip_start_time: "2024-07-01",
        //     nickname: 'zhangsan',
        //     avatar_objkey: null,
        //     admin: true,
        //     department: null,
        //     vip_end_time: "2024-07-01",
        //     phone_num: null,
        //     username: '张三',
        //     employeeNum: null,
        //     surname: null,
        //     id: 1,
        //     uuid: "111",
        //     registered_on: "2024-07-02 02:08:34",
        //     email: "zhangs@jaid.cn",
        //     age: "18-24",
        //     key: 1,
        // },
    ],
    user_statistics_today_num: '',
    user_statistics_week_num: '',
    user_statistics_month_day_avg: '',
    conversation_count_data: [],
    active_uuid: '',
    personal_conversation_data: [],
    knowledge_base_menu: [
        // {
        //     label: '灵石AI',
        //     key: 'llm',
        // },
        // {
        //     label: '天工',
        //     key: 'tiangong',
        // },
    ],
    knowledge_base_list: [],
    knowledge_base_list_search_name: '',
    active_model: '',
    active_model_name: '',
    knowledge_base_show_content: '',        // '', 'slice', 'upload'
    file_slice_list: [],
    version_update_list: [],
    is_show_version_update_image: false,
    show_version_update_image_id: '',

    permission_user_list: [
        // {
        //     admin: true,
        //     age: "18-24",
        //     avatar_objkey: null,
        //     city: "上海",
        //     department: null,
        //     email: "zhangs@jaid.cn",
        //     employeeNum: null,
        //     gender: "man",
        //     groups: ['aa', 'bb'],
        //     id: 1,
        //     last_login: "2024-07-23 08:05:38",
        //     nickname: "张三",
        //     occupation: "学生",
        //     permissions: {
        //         '全网搜索专家': ['read', 'write', 'execute', 'delete'],
        //         '办公设计专家': ['read', 'write', 'execute', ],
        //         '可持续专家': ['read', 'execute', 'delete'],
        //         '商业设计专家': ['read', 'write', 'delete'],
        //         '基础专家（前AI助手）': ['read', 'write', 'execute', 'delete'],
        //         '灵石1.0': ['read', 'write', 'execute', 'delete'],
        //         '物料知识专家': ['read', 'execute', 'delete'],
        //         '规范查询专家': ['read', 'write'],
        //         '软件技巧专家': ['read', 'execute', 'delete'],
        //     },
        //     phone_num: null,
        //     registered_on: "2024-07-01 18:08:34",
        //     surname: null,
        //     username: "张三",
        //     uuid: "111",
        //     vip_end_time: "2024-07-01",
        //     vip_level: 1,
        //     vip_start_time: "2024-07-01",
        // },
    ],
    permission_user_page: 'table',  // table personal
    permission_user_uuid: '',
    permission_group_list: [
        // {
        //     created_at: "2024-07-19 08:21:26",
        //     description: null,
        //     id: 1,
        //     name: "group_standard",
        //     organization_id: 1,
        //     permissions: {
        //         '全网搜索专家': ['read', 'execute'],
        //         '办公设计专家': ['read'],
        //         '可持续专家': ['read'],
        //         '商业设计专家': ['read'],
        //         '基础专家（前AI助手）': ['read', 'execute'],
        //         '灵石1.0': ['read', 'execute'],
        //         '物料知识专家': ['read'],
        //         '规范查询专家': ['read', 'execute'],
        //         '软件技巧专家': ['read'],
        //     },
        //     updated_at: "2024-07-19 08:21:26",
        //     users: [
        //         {
        //             admin: false,
        //             age: "18-24",
        //             avatar_objkey: null,
        //             city: "北京",
        //             department: "研发测试部",
        //             email: "xiaom@jaid.cn",
        //             employeeNum: null,
        //             gender: "man",
        //             id: 4,
        //             last_login: "2024-07-23 07:58:24",
        //             nickname: "张三",
        //             occupation: "学生",
        //             phone_num: null,
        //             registered_on: "2024-07-05 16:50:33",
        //             surname: null,
        //             username: null,
        //             uuid: "20240705",
        //             vip_end_time: "2024-07-05",
        //             vip_level: 1,
        //             vip_start_time: "2024-07-05",
        //         },
        //         {
        //             admin: false,
        //             age: "18-24",
        //             avatar_objkey: null,
        //             city: "北京",
        //             department: "研发测试部",
        //             email: "xiaom@jaid.cn",
        //             employeeNum: null,
        //             gender: "man",
        //             id: 5,
        //             last_login: "2024-07-23 07:58:24",
        //             nickname: "小明",
        //             occupation: "学生",
        //             phone_num: null,
        //             registered_on: "2024-07-05 16:50:33",
        //             surname: null,
        //             username: null,
        //             uuid: "20240705",
        //             vip_end_time: "2024-07-05",
        //             vip_level: 1,
        //             vip_start_time: "2024-07-05",
        //         },
        //     ]
        // },
    ],
    permission_group_page: 'table',  // table personal
    permission_group_name: '',

    knowledge_base_options: [
        // {
        //     value: 'gpt-4o',
        //     label: 'gpt-4o',
        // },
        // {
        //     value: 'qwen-max',
        //     label: 'qwen-max',
        // },
    ],

    edit_knowledge_base_name: '',

    filter_dates: [],
})

const reducer = (state=defaultState, action) => {
    switch(action.type) {
        case actionTypes.SET_ACTIVE_NAVBAR_ACTION:
            return state.set('active_navbar', action.value)
        case actionTypes.SET_USER_STATISTICS_DATA_ACTION:
            return state.merge({
                'user_statistics_data': fromJS(action.data),
        })
        case actionTypes.SET_USER_STATISTICS_TODAY_LOGIN_NUM:
            return state.set('user_statistics_today_num', action.value)
        case actionTypes.SET_USER_STATISTICS_WEEK_LOGIN_NUM:
            return state.set('user_statistics_week_num', action.value)
        case actionTypes.SET_USER_STATISTICS_MONTH_DAY_AVG:
            return state.set('user_statistics_month_day_avg', action.value)
        case actionTypes.SET_CONVERSATION_COUNT_DATA_ACTION:
            return state.merge({
                'conversation_count_data': fromJS(action.data),
            })
        case actionTypes.SET_ACTIVE_UUID_ACTION:
            return state.set('active_uuid', action.value)
        case actionTypes.SET_PERSONAL_CONVERSATION_DATA_ACTION:
            return state.merge({
                'personal_conversation_data': fromJS(action.data),
            })
        case actionTypes.SET_KNOWLEDGE_BASE_MENU_ACTION:
            return state.merge({
                'knowledge_base_menu': fromJS(action.data),
            })
        case actionTypes.SET_KNOWLEDGE_BASE_LIST_ACTION:
            return state.merge({
                'knowledge_base_list': fromJS(action.data),
            })
        case actionTypes.SET_KNOWLEDGE_BASE_LIST_SEARCH_NAME:
            return state.set('knowledge_base_list_search_name', action.value)
        case actionTypes.SET_ACTIVE_MODEL_ACTION:
            return state.merge({
                'active_model': action.model_key,
                'active_model_name': action.model_name,
            })
        case actionTypes.SET_KNOWLEDGE_BASE_SHOW_CONTENT:
            return state.set('knowledge_base_show_content', action.value)
        case actionTypes.SET_FILE_SLIT_LIST_ACTION:
            return state.merge({
                'file_slice_list': fromJS(action.data),
            })
        case actionTypes.SET_VERSION_UPDATE_LIST_ACTION:
            return state.merge({
                'version_update_list': fromJS(action.data),
            })
        case actionTypes.IS_SHOW_VERSION_UPDATE_IMAGE:
            return state.set('is_show_version_update_image', action.status)
        case actionTypes.SHOW_VERSION_UPDATE_IMAGE_ID:
            return state.set('show_version_update_image_id', action.value)
        case actionTypes.SET_PERMISSION_USER_LIST_ACTION:
            return state.merge({
                'permission_user_list': fromJS(action.data),
            })
        case actionTypes.SET_PERMISSION_USER_PAGE_ACTION:
            return state.set('permission_user_page', action.value)
        case actionTypes.SET_PERMISSION_USER_UUID_ACTION:
            return state.set('permission_user_uuid', action.value)

        case actionTypes.SET_PERMISSION_GROUP_LIST_ACTION:
            return state.merge({
                'permission_group_list': fromJS(action.data),
            })
        case actionTypes.SET_PERMISSION_GROUP_PAGE_ACTION:
            return state.set('permission_group_page', action.value)
        case actionTypes.SET_PERMISSION_GROUP_NAME_ACTION:
            return state.set('permission_group_name', action.value)
        case actionTypes.SET_KNOWLEDGE_BASE_OPTIONS_ACTION:
            return state.merge({
                'knowledge_base_options': fromJS(action.data),
            })
        case actionTypes.SET_EDIT_KNOWLEDGE_BASE_NAME:
            return state.set('edit_knowledge_base_name', action.value)
        case actionTypes.SET_FILTER_DATES:
            return state.merge({
                'filter_dates': fromJS(action.data),
            })
        default:
            return state
    }
}

export default reducer