const experienceIcons = [
    require('../statics/lv1_icon.png'),
    require('../statics/lv2_icon.png'),
    require('../statics/lv3_icon.png'),
    require('../statics/lv4_icon.png'),
    require('../statics/lv5_icon.png'),
    require('../statics/lv6_icon.png'),
]


function checkExperience(levels, exp) {
    for (let i = 0; i < levels.length; i++) {
        const lvStartExp = i === 0 ? 0 : levels[i - 1];
        const lvExp = levels[i] - lvStartExp;
        const nextLvExp = exp === levels[i] + 1;

        // 计算1/3和2/3的经验点
        const oneThirdMark = lvStartExp + Math.ceil(lvExp / 3);
        const twoThirdsMark = lvStartExp + Math.ceil((lvExp * 2) / 3);

        if (nextLvExp) {
            return { showModal: true, status: '升级' };
        } else if (exp === oneThirdMark) {
            return { showModal: true, status: '还差 2/3 就达到' };
        } else if (exp === twoThirdsMark) {
            return { showModal: true, status: '还差 1/3 就达到' };
        }
    }

    return { showModal: false, status: '' }; // 默认情况，不显示弹窗
}

export {
    experienceIcons,
    checkExperience,
}
