import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Input } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons';

import { actionCreators } from '../../store';
import styles from './index.module.css'


function KnowledgeBaseFileUploadButton() {
    const dispatch = useDispatch()
    let active_model_name = useSelector(state => state.get('admin').get('active_model_name'))
    let knowledge_base_list_search_name = useSelector(state => state.get('admin').get('knowledge_base_list_search_name'))

    useEffect(() => {
        return () => {
            dispatch(actionCreators.setKnowledgeBaseListSearchName(''))
        }
    }, [])

    const handleClick = () => {
        dispatch(actionCreators.setKnowledgeBaseShowContentAction('upload'))
    }

    const handleOnChange = (e) => {
        let value = e.target.value
        dispatch(actionCreators.setKnowledgeBaseListSearchName(value))
    }

    return (
        <div className={styles.main}>
            <Button
                type="primary"
                shape="round"
                icon={<CloudUploadOutlined />}
                onClick={handleClick}
            >
                {active_model_name === '图片知识库' ? '上传图片' : '上传文件'}
            </Button>

            <div className={styles.search_box}>
                <Input
                    placeholder="要查询的文件名"
                    value={knowledge_base_list_search_name}
                    onChange={handleOnChange}
                    style={{width: '70%'}}
                />
            </div>
        </div>
    )
}

export default KnowledgeBaseFileUploadButton