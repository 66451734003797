import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Button, Modal, message } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';

import KnowledgeBaseContent from '../../components/KnowledgeBaseContent'
import { actionCreators } from '../../store'
import { actionCreators as homeActionCreators } from '../../../home/store'
import styles from './index.module.css'
import KnowledgeBaseAddModal from '../../components/KnowledgeBaseAddModal'
import KnowledgeBaseContentSlice from '../../components/KnowledgeBaseContentSlice'
import KnowledgeBaseFileUploadButton from '../KnowledgeBaseFileUploadButton'
import KnowledgeBaseFileUpload from '../KnowledgeBaseFileUpload'
import KnowledgeBaseImageUpload from '../KnowledgeBaseImageUpload'
import KnowledgeBaseEditModal from '../KnowledgeBaseEditModal'


const KnowledgeBaseMenu = ({ handleShowEditModal }) => {
    const dispatch = useDispatch()
    let items = useSelector(state => state.get('admin').get('knowledge_base_menu')).toJS()
    let active_model = useSelector(state => state.get('admin').get('active_model'))

    const handleDelete = (name) => {
        console.log("delete name = ", name);
        if (name === '图片知识库') {
            message.info("该知识库不能被删除，请联系管理员")
            return
        }

        Modal.confirm({
            title: '删除模型',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除该模型以及该模型的相关文件？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                dispatch(actionCreators.deleteKnowledgeBase(name))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleEdit = (name) => {
        console.log("handleEdit");
        handleShowEditModal()
        dispatch(actionCreators.setEditKnowledgeBaseName(name))
    }

    const createMenuItems = () => items.map(item => ({
        ...item,
        label: (
            item.label === '图片知识库' ? (
                <>
                    {item.label}
                </>
            ) : (
                <>
                    {item.label}
                    <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={(e) => {
                            e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
                            handleEdit(item.label);
                        }}
                        style={{ float: 'right', marginLeft: 'auto' }}
                    />
                    <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            e.stopPropagation(); // 防止点击事件冒泡到 Menu.Item
                            handleDelete(item.label);
                        }}
                        style={{ float: 'right', marginLeft: 'auto' }}
                        danger
                    />
                </>
            )
        ),
    }))

    const onClick = (e) => {
        console.log('click ', e.key);
        let active_model = e.key
        let obj = items.find(item => item.key === active_model)
        dispatch(actionCreators.setActiveModelAction(obj.key, obj.label))
        dispatch(actionCreators.setKnowledgeBaseShowContentAction(''))
        dispatch(actionCreators.setKnowledgeBaseListSearchName(''))
    }

    return (
        <Menu
            onClick={onClick}
            selectedKeys={[active_model]}
            mode="inline"
            items={createMenuItems()}
            className={styles.knowledge_base_menu}
        />
    )
}


const KnowledgeBase = () => {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    let knowledge_base_show_content = useSelector(state => state.get('admin').get('knowledge_base_show_content'))
    let active_model_name = useSelector(state => state.get('admin').get('active_model_name'))

    useEffect(() => {
        console.log("获取模型列表");
        dispatch(actionCreators.fetchKnowledgeBaseList())
        dispatch(homeActionCreators.getKnowledgeBaseCategorys())
    }, [])

    const handleShowModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleShowEditModal = () => {
        setIsEditModalOpen(true)
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false)
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    数据管理
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.butn_box}>
                        <Button block onClick={handleShowModal}>添加新的知识库</Button>
                    </div>
                    <div className={styles.menu_box}>
                        <KnowledgeBaseMenu handleShowEditModal={handleShowEditModal} />
                    </div>
                </div>
                <div className={styles.right}>
                    {
                        knowledge_base_show_content === 'slice' ? (
                            <KnowledgeBaseContentSlice />
                        ) : (
                            knowledge_base_show_content === 'upload' ? (
                                active_model_name === '图片知识库' ? (
                                    <KnowledgeBaseImageUpload />
                                ) : (
                                    <KnowledgeBaseFileUpload />
                                )
                            ) : (
                                <>
                                    <KnowledgeBaseFileUploadButton />
                                    <KnowledgeBaseContent />
                                </>
                            )
                        )
                    }

                </div>
            </div>

            <KnowledgeBaseAddModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
            />
            <KnowledgeBaseEditModal
                isModalOpen={isEditModalOpen}
                handleCloseModal={handleCloseEditModal}
            />
        </div>
    )
}

export default KnowledgeBase;