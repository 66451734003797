import React, { useState, useRef } from 'react';
import { Input } from 'antd';
import styles from './index.module.css'

const VerificationCodeInput = ({ length, inputs, setInputs }) => {
    // const [inputs, setInputs] = useState(new Array(length).fill(''));
    const inputRefs = useRef(new Array(length).fill().map(() => React.createRef()));

    const processInput = (e, slot) => {
        const value = e.target.value;

        // 只处理数字输入
        if (/^[0-9]$/.test(value) || value === '') {
            setInputs(inputs.map((input, index) => (index === slot ? value : input)));

            // 当输入数字并且不是最后一个输入框时，移动到下一个输入框
            if (/^[0-9]$/.test(value) && slot < length - 1) {
                inputRefs.current[slot + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (e, slot) => {
        // 如果按下了退格键，并且当前输入框为空或者需要删除当前数字
        if (e.key === "Backspace") {
            if (inputs[slot]) {
                // 如果当前输入框有值，则清空当前输入框
                setInputs(inputs.map((input, index) => (index === slot ? '' : input)));
            } else if (slot !== 0) {
                // 如果当前输入框无值，则删除前一个输入框的值，并移动焦点到前一个输入框
                setInputs(inputs.map((input, index) => (index === slot - 1 ? '' : input)));
                inputRefs.current[slot - 1].current.focus();
            }
        }
    };

    return (
        <div className={styles.main}>
            {
                inputs.map((input, index) => (
                    <Input
                        key={index}
                        maxLength={1}
                        onChange={(e) => processInput(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        value={input}
                        ref={inputRefs.current[index]}
                        className={`${styles.input} ${input !== '' ? styles.input_active : null}`}
                    />
                ))
            }

            {/* <button onClick={() => { console.log("inputs = ", inputs);} }>查看用户输入的数字</button> */}
        </div>
    );
};

export default VerificationCodeInput;
