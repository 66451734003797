import React, { Component } from 'react';
import { connect } from 'react-redux'
import { message } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import { toggleChatTypeActive, generateOptionsByCategory,
    containsWord,
} from '../../../../utils'

import menuSearchIcon from '../../../../statics/menu_search_icon.png'
import lockImg from '../../../../statics/butn_box_lock.png'


function OptionsRow({ main_rows, addExpertModel, addNewConversation }) {
    return main_rows.map((item) => {
        return (
            <div className={styles.expert_box} key={item.value}>
                <div className={styles.expert_title}>{item.value}</div>
                <RowItems
                    items={item.items}
                    addExpertModel={addExpertModel}
                    addNewConversation={addNewConversation}
                />
            </div>
        )
    })
}

function RowItems({ items, addExpertModel, addNewConversation }) {
    const onBookmarkClick = (e, newVal) => {
        e.preventDefault()
        e.stopPropagation()
        addExpertModel(newVal)
    }

    const onModelClick = (permissions, newVal) => {
        if (containsWord(permissions, 'execute')) {
            addNewConversation(newVal)
        }
    }

    return (
        <div className={styles.items_butn_box}>
            {
                items.map((item) => {
                    let icon = item.avatar

                    return (
                        <div
                            className={styles.butn_box}
                            key={item.value}
                            onClick={() => {onModelClick(item.permissions, item.value)}}
                        >
                            <div className={styles.butn_imgs}>
                                <img src={icon} className={styles.expert_img} />
                                <div
                                    className={`${styles.bookmark_img} ${item.active ? styles.bookmark_img_active : ''}`}
                                    onClick={(e) => {onBookmarkClick(e, item.value)}}
                                >
                                </div>
                            </div>
                            <div className={styles.butn_texts}>
                                <div className={styles.butn_text_title}>{item.label}</div>
                                <div className={styles.butn_text_info}>{item.info}</div>
                            </div>

                            {
                                containsWord(item.permissions, 'execute') ? null : (
                                    <div className={styles.butn_box_lock_box}>
                                        <div className={styles.butn_box_lock}>
                                            <img src={lockImg} className={styles.lock_img} />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

class ChatTypeDetailScreen extends Component {
    render() {
        let expert_category_list = this.props.expert_category_list.toJS()
        let chat_type_options = this.props.chat_type_options.toJS()

        let main_rows  = generateOptionsByCategory(expert_category_list, chat_type_options)

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.header_box}>
                        <div className={styles.header_title}>所有专家模型</div>
                        <div className={styles.header_explain}>在这里选择最合适你的专家模型吧！</div>
                        <div className={styles.header_search}>
                            <input
                                type={"text"}
                                placeholder={'搜索'}
                                className={styles.search}
                                // onChange={this.onSearchChange.bind(this)}
                                // value={this.props.menu_search_content}
                            />
                            <img src={menuSearchIcon} className={styles.search_icon} />
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.section_box}>
                        {
                            expert_category_list.map((item) => {
                                return (
                                    <div
                                        key={item.value}
                                        className={`${styles.section_butn} ${item.active ? styles.section_butn_active : ''}`}
                                        onClick={() => {this.handleSelectExpert(item.value)}}
                                    >
                                        {item.label}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className={styles.main}>
                    <div className={styles.rows_box}>
                        <OptionsRow
                            main_rows={main_rows}
                            addExpertModel={this.addExpertModel.bind(this)}
                            addNewConversation={this.addNewConversation.bind(this)}
                        />
                    </div>
                </div>

            </div>
        )
    }

    handleSelectExpert(value) {
        let expert_category_list = this.props.expert_category_list.toJS()
        expert_category_list.forEach(element => {
            if (element.value === value) {
                element.active = true
            } else {
                element.active = false
            }
        })

        this.props.handleExpertCategoryListAction(expert_category_list)
    }

    addExpertModel(value) {
        if (value !== 'link_stone') {
            let chat_type_options = this.props.chat_type_options.toJS()
            let result = toggleChatTypeActive(chat_type_options, value)

            if (!result.isUpdated) {
                message.info("无法更新状态，因为固定到侧边栏的专家模型已经达到上限。")
            } else {
                this.props.handleChatTypeOptionsAction(result.chat_type_options)
            }
        }
    }

    addNewConversation(value) {
        this.props.handleChatTypeChangeAction(value)
    }
}

const mapStateToProps = (state) => {
    return {
        chat_type_options: state.get('home').get('chat_type_options'),
        expert_category_list: state.get('home').get('expert_category_list'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleExpertCategoryListAction(data) {
            dispatch(actionCreators.expertCategoryListAction(data))
        },
        handleChatTypeOptionsAction(data) {
            dispatch(actionCreators.chatTypeOptionsAction(data))
        },
        handleChatTypeChangeAction(value) {
            dispatch(actionCreators.chatTypeChangeAction(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatTypeDetailScreen)