import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Table, Space, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined, CheckCircleTwoTone, LoadingOutlined, CloseCircleTwoTone } from '@ant-design/icons';

import styles from './index.module.css'
import { act } from 'react';
import { actionCreators } from '../../store';
import { convertBytesToMB, convertUTCToLocalTime } from '../../../../utils';

const KnowledgeBaseTable = () => {
    const columns = [
        {
            title: '文件名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '文件格式',
            dataIndex: 'file_ext',
            key: 'file_ext',
        },
        {
            title: '文件大小',
            render: (_, record) => (
                <span>{`${convertBytesToMB(record.size)} MB`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.size > b.size) {
                        return 1
                    } else if (a.size < b.size) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: '上传时间',
            render: (_, record) => (
                <span>{`${convertUTCToLocalTime(record.created_at)}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.created_at > b.created_at) {
                        return 1
                    } else if (a.created_at < b.created_at) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'created_at',
        },
        {
            title: '更新时间',
            render: (_, record) => (
                <span>{`${convertUTCToLocalTime(record.updated_at)}`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.updated_at > b.updated_at) {
                        return 1
                    } else if (a.updated_at < b.updated_at) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'updated_at',
        },
        {
            title: '文件版本',
            dataIndex: 'file_version',
            render: (_, record) => (
                <span>{`第 ${record.file_version} 版文件`}</span>
            ),
            sorter: {
                compare: (a, b) => {
                    if (a.file_version > b.file_version) {
                        return 1
                    } else if (a.file_version < b.file_version) {
                        return -1
                    } else {
                        return 0
                    }
                },
            },
            key: 'file_version',
        },
        {
            title: '状态',
            key: 'has_done',
            render: (_, record) => {
                let status_list = ['Failed', 'Cancelled']
                if (record.status === 'Success') {
                    return (
                        <Space>
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                            <span>导入完成</span>
                        </Space>
                    )
                } else if (status_list.includes(record.status)) {
                    return (
                        <Space>
                            <CloseCircleTwoTone twoToneColor="#f00" />
                            <span>导入失败</span>
                        </Space>
                    )
                } else {
                    return (
                        <Space>
                            <Spin indicator={<LoadingOutlined spin />} size="small" />
                            <span>导入中...</span>
                        </Space>
                    )
                }
            }
        },
        {
            title: '操作',
            // dataIndex: 'gender',
            key: 'id',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => {handleOrigin()}}>原文详情</a>
                    <a onClick={() => {handleSlice(record.name)}}>切片详情</a>
                    <a onClick={() => {handleDelete(record.name)}}>删除</a>
                </Space>
            ),
        },
    ];

    const dispatch = useDispatch()
    const [baseList, setBaseList] = useState([])
    const [intervalId, setIntervalId] = useState(null);
    let knowledge_base_list = useSelector(state => state.get('admin').get('knowledge_base_list'))
    let knowledge_base_list_search_name = useSelector(state => state.get('admin').get('knowledge_base_list_search_name')).trim()
        let active_model = useSelector(state => state.get('admin').get('active_model'))
    let active_model_name = useSelector(state => state.get('admin').get('active_model_name'))

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const handleOrigin = () => {
        console.log("原文详情");
    }

    const handleSlice = (file_name) => {
        console.log("切片详情");
        dispatch(actionCreators.setKnowledgeBaseShowContentAction('slice'))
        dispatch(actionCreators.fetchSliceDetails(active_model_name, file_name))
    }

    const handleDelete = (file_name) => {
        console.log("删除");

        Modal.confirm({
            title: '删除文件',
            icon: <ExclamationCircleOutlined />,
            content: '请确认是否要删除文件："' + file_name + '" ？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                dispatch(actionCreators.deleteKnowledgeBaseFile(active_model_name, file_name))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    useEffect(() => {
        // 清除之前的定时任务
        if (intervalId) {
            clearInterval(intervalId)
            setIntervalId(null)
        }

        let data = knowledge_base_list.toJS()
        let obj = data.find(item => item.collection === active_model)
        if (obj && obj.files) {
            let filter_file_list = obj.files.filter(item =>
                item.name.includes(knowledge_base_list_search_name)
            )
            setBaseList(filter_file_list)

            // 检查是否有文件的 progress 不等于 100
            let status_list = ['Success', 'Failed', 'Cancelled']
            const hasIncompleteFiles = obj.files.some(file => !status_list.includes(file.status))
            if (hasIncompleteFiles) {
                // 如果有未完成的文件，则设置一个新的定时器
                const newIntervalId = setInterval(() => {
                    dispatch(actionCreators.fetchSingleKnowledgeBaseList(active_model))
                }, 5000) // 5秒钟轮询一次

                setIntervalId(newIntervalId) // 保存定时器 ID，以便将来可以清除它
            }
        }

        // 组件卸载时清除定时器
        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
    }, [active_model, knowledge_base_list, knowledge_base_list_search_name])

    return (
        <Table
            columns={columns}
            dataSource={baseList}
            onChange={onChange}
        />
    )
}


const KnowledgeBaseContent = () => {
    return (
        <div className={styles.main}>
            <KnowledgeBaseTable />
        </div>
    )
}

export default KnowledgeBaseContent