import { parseISO, format, addHours } from 'date-fns';

var convertUTCToLocalTime = function(utcDateString) {
    // 解析 ISO 格式的 UTC 日期字符串
    const date = parseISO(utcDateString);

    // 东八区 (UTC+8)
    const offset = 8
    const localDate = addHours(date, offset)

    // 格式化日期和时间
    // 您可以根据需要调整 'yyyy-MM-dd HH:mm:ss' 格式字符串
    return format(localDate, 'yyyy-MM-dd HH:mm:ss')
}

var convertGender = function(value) {
    if (value === 'man') {
        return '男'
    } else if (value === 'woman') {
        return '女'
    } else if (value === 'other') {
        return '其他'
    } else {
        return ''
    }
}

var convertCity = function(value) {
    let obj = {
        'beijing': '北京',
        'shanghai': '上海',
        'guangzhou': '广州',
        'shenzhen': '深圳',
        'chengdu': '成都',
        'wuhan': '武汉',
        'haikou': '海口',
        'xian': '西安',
        'dalian': '大连',
    }

    if (obj[value]) {
        return obj[value]
    } else {
        return value
    }
}

var convertOccupation = function(value) {
    let obj = {
        'freelancers': '自由职业者',
        'interior_designer': '室内设计师',
        'other': '其他',
        'student': '学生',
        'architecture_designer': '建筑设计师',
        'individual_enthusiasts': '个人爱好者',
    }

    if (obj[value]) {
        return obj[value]
    } else {
        return value
    }
}


var convertAge = function(value) {
    let obj = {
        '18': '18-24',
        '25': '25-34',
        '35': '35-44',
        '45': '45-54',
        '55': '55-64',
        '65': '65岁以上',
    }

    if (obj[value]) {
        return obj[value]
    } else {
        return value
    }
}

var convertModelName = function(chat_type_options, value) {
    let obj = chat_type_options.find(item => item.value === value)
    if (obj && obj.label) {
        return obj.label
    } else {
        return value
    }
}

var convertModelMenu = function(data) {
    let d = data.map((item) => {
        return {
            label: item.name,
            key: item.collection,
        }
    })

    return d
}

var convertBytesToMB = function(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
}

var convertCategorysToLocalFormat = function(data) {
    const transformedData = data.map(item => ({
        value: item.id,
        label: item.name,
        active: false,
    }));

    // 添加元素到数组的开始位置
    transformedData.unshift({ label: '所有', value: 'all', active: true, });

    return transformedData
}

const convertPermissionsToTableData = (permissionsObj) => {
    return Object.entries(permissionsObj).map(([resource, perms], index) => ({
        key: String(index),
        resource,
        permissions: {
            read: perms.includes('read'),
            write: perms.includes('write'),
            execute: perms.includes('execute'),
            delete: perms.includes('delete')
        },
    }));

    // 输入
    // {
    //     "测试1.0": [
    //       "read",
    //       "write",
    //       "execute",
    //       "delete"
    //     ],
    //     "基础AI助手": [
    //       "read",
    //       "write",
    //       "execute",
    //       "delete"
    //     ],
    // }

    // 输出
    // [
    //     {
    //       key: '0',
    //       user: '测试1.0',
    //       permissions: {
    //         read: true,
    //         write: false,
    //         execute: true,
    //         delete: false
    //       }
    //     },
    //     {
    //       key: '1',
    //       user: '基础AI助手',
    //       permissions: {
    //         read: false,
    //         write: true,
    //         execute: false,
    //         delete: false
    //       }
    //     }
    // ]
};

const convertTableDataToPermissions = (tableData) => {
    let permissionsObj = {};

    tableData.forEach(item => {
        let userPermissions = [];

        // 检查每种权限是否被授予，并添加到权限列表中
        if (item.permissions.read) userPermissions.push('read');
        if (item.permissions.write) userPermissions.push('write');
        if (item.permissions.execute) userPermissions.push('execute');
        if (item.permissions.delete) userPermissions.push('delete');

        // 将权限列表与用户名关联在一起
        permissionsObj[item.resource] = userPermissions;
    });

    return permissionsObj;

    // 输入
    // [
    //     {
    //       key: '0',
    //       user: '测试1.0',
    //       permissions: {
    //         read: true,
    //         write: false,
    //         execute: true,
    //         delete: false
    //       }
    //     },
    //     {
    //       key: '1',
    //       user: '基础AI助手',
    //       permissions: {
    //         read: false,
    //         write: true,
    //         execute: false,
    //         delete: false
    //       }
    //     }
    // ]

    // 输出
    // {
    //     "测试1.0": ["read", "execute"],
    //     "基础AI助手": ["write"]
    // }
};

var convertKnowledgeBaseConfigToOptions = function(data) {
    const transformedData = data.map(item => ({
        value: item,
        label: item,
    }));

    return transformedData
}

var mergeWithIsActive = function(arrayA, arrayB) {
    const mergedValuesMap = new Map();

    function mergeItem(item) {
        const existingItem = mergedValuesMap.get(item.label);
        // 如果 map 中没有相同的 label 或者当前项目的 is_active 为 true，则添加或更新到 map 中
        if (!existingItem) {
            mergedValuesMap.set(item.label, item);
        } else {
            // 如果两个项目中任意一个的 is_active 为 true，则保留 is_active 为 true 的项目
            if (item.is_active || existingItem.is_active) {
                mergedValuesMap.set(item.label, item.is_active ? item : existingItem);
            } else {
                // 如果两个项目的 is_active 都为 false，则从 map 中删除该 label
                mergedValuesMap.delete(item.label);
            }
        }
    }

    // 遍历数组 arrayA 和 arrayB 并合并元素
    arrayA.forEach(mergeItem);
    arrayB.forEach(mergeItem);

    // 将 map 转换成数组
    return Array.from(mergedValuesMap.values());
}

var getCurrentFormattedTime = function() {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = now.getDate().toString().padStart(2, '0');

    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

var getCurrentTodayFormattedTime = function() {
    // 获取今天零时零分零秒的时间
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = now.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day} 00:00:00`;
}

var getStartOfWeekDate = function() {
    // 获取这周周一的零时零分零秒的时间
    // 当前时间
    const now = new Date();

    // 获取当前是周几（0 表示周日，1 表示周一... 6 表示周六）
    const dayOfWeek = now.getDay();

    // 计算与本周周一的差值
    const distanceToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;

    // 获取本周周一的日期
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() + distanceToMonday);

    // 设置时间为0点0分0秒
    startOfWeek.setHours(0, 0, 0, 0);

    // 格式化输出
    const year = startOfWeek.getFullYear();
    const month = String(startOfWeek.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要+1
    const day = String(startOfWeek.getDate()).padStart(2, '0');
    const hours = String(startOfWeek.getHours()).padStart(2, '0');
    const minutes = String(startOfWeek.getMinutes()).padStart(2, '0');
    const seconds = String(startOfWeek.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function getStartOfMonth() {
    // 获取这个月1号的零时零分零秒的时间
    const now = new Date(); // 获取当前日期和时间
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0); // 设置为这个月1号的0时0分0秒
    const year = startOfMonth.getFullYear();
    const month = (startOfMonth.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = startOfMonth.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
}

var getStartOfToday = function() {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const day = now.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

var compareVersions = (v1, v2) => {
    const parts1 = v1.split('.').map(Number);
    const parts2 = v2.split('.').map(Number);

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const num1 = i < parts1.length ? parts1[i] : 0;
        const num2 = i < parts2.length ? parts2[i] : 0;

        if (num1 > num2) return 1;
        if (num1 < num2) return -1;
    }

    return 0;
}

var incrementVersion = (versionList) => {
    if (!versionList.length) return '1.0.0'; // 如果列表为空，则返回初始版本号

    const sortedList = [...versionList].sort(compareVersions); // 先对现有版本列表进行排序
    const latestVersion = sortedList[sortedList.length - 1]; // 获取最新（最大）的版本号

    const parts = latestVersion.split('.').map(Number); // 将版本号拆分成各个部分并转换为数字
    parts[parts.length - 1] += 1; // 递增最后一部分（修订号）

    return parts.join('.'); // 将修改后的部分重新组合成字符串形式的版本号
}

var transformCategorizeData = (data) => {
    // 定义一个空数组来存储结果
    let result = [];

    // 使用for...in循环遍历对象的键
    for (let key in data) {
        if (data.hasOwnProperty(key)) { // 确保属性属于对象自身，而不是原型链上的属性
            result.push({
                value: data[key],
                name: key
            });
        }
    }

    return result;
}

export {
    convertUTCToLocalTime,
    convertGender,
    convertCity,
    convertOccupation,
    convertAge,
    convertModelName,
    convertModelMenu,
    convertBytesToMB,
    convertCategorysToLocalFormat,
    convertPermissionsToTableData,
    convertTableDataToPermissions,
    convertKnowledgeBaseConfigToOptions,
    mergeWithIsActive,
    getCurrentFormattedTime,
    getCurrentTodayFormattedTime,
    getStartOfWeekDate,
    getStartOfMonth,
    getStartOfToday,
    compareVersions,
    incrementVersion,
    transformCategorizeData,
}