const chatTypeIcons = {
    llm_chat: require('../statics/chat_type_sel_project.png'),
    bailian_chat: require('../statics/chat_type_sel_standard.png'),
    tiangong_search: require('../statics/chat_type_sel_work.png'),
    project_manager_expert: require('../statics/chat_type_sel_project_manager.png'),
    sustainable_experts: require('../statics/sustainable_experts.png'),
    material_experts: require('../statics/material_experts.png'),
    software_experts: require('../statics/software_experts.png'),
    business_experts: require('../statics/business_experts.png'),
    office_experts: require('../statics/office_experts.png'),
    agent_chat: require('../statics/ai_link_stone.gif'),
}

const chatTypeSidebarIcons = {
    llm_chat: require('../statics/sidebar_chat_type_project.png'),
    bailian_chat: require('../statics/sidebar_chat_type_standard.png'),
    tiangong_search: require('../statics/sidebar_chat_type_work.png'),
    project_manager_expert: require('../statics/sidebar_chat_type_project_manager.png'),
    sustainable_experts: require('../statics/sidebar_chat_type_sustainable.png'),
    material_experts: require('../statics/sidebar_chat_type_material.png'),
    software_experts: require('../statics/sidebar_chat_type_software.png'),
    business_experts: require('../statics/sidebar_chat_type_business.png'),
    office_experts: require('../statics/sidebar_chat_type_office.png'),
    agent_chat: require('../statics/sidebar_chat_type_line_stone.png'),
}

var toggleChatTypeActive = function(chat_type_options, chat_type) {
    let activeCount = 0;
    let isUpdated = false;

    // 统计当前激活的数量
    chat_type_options.forEach(option => {
        if (option.active) activeCount++;
    });

    // 遍历 chat_type_options 并修改对应 chat_type 的 active 状态
    chat_type_options = chat_type_options.map(option => {
        if (option.value === chat_type) {
            // 当要激活一个选项时，检查是否已达到最大激活数
            if (!option.active && activeCount >= 5) {
                isUpdated = false; // 不能再激活更多选项
            } else {
                option.active = !option.active; // 切换 active 状态
                isUpdated = true;
                // 更新 active 计数
                option.active ? activeCount++ : activeCount--;
            }
        }
        return option;
    });

    // 返回更新后的 chat_type_options 和是否成功更新的标志
    return { chat_type_options, isUpdated };
}

var generateOptionsByCategory = function(expert_category_list, chat_type_options) {
    let selectedValue = expert_category_list.find(option => option.active)?.value

    if (selectedValue === 'all') {
        // 当选择 '所有' 时，返回包含所有分类的列表
        return expert_category_list
            .filter(classify => classify.value !== 'all') // 排除 '所有' 分类本身
            .map(classify => {
                return {
                    value: classify.label,
                    items: chat_type_options.filter(option => option.category === classify.label),
                }
            })
    } else {
        // 否则，只返回所选分类的列表
        const selectedClassify = expert_category_list.find(classify => classify.value === selectedValue);
        return [{
            value: selectedClassify.label,
            items: chat_type_options.filter(option => option.category === selectedClassify.label),
        }]
    }

    /*
        [
            {
                value: '分析专家',
                items: [
                    { value: 'llm_chat', label: '必应搜索', category: 'analysis', },
                    { value: 'bing_search2', label: '必应搜索2', category: 'analysis', },
                ],
            }
        ]
    */
}


var findObjFromChatTypeOptionsByValue = function(value, chat_type_options) {
    // key
    // 'search_internet_tool'
    // 'fallback_generic_tool'
    // 'standard_expert_tool'
    // 'sustainability_expert_tool'
    // 'material_knowledge_expert_tool'
    // 'software_technologist_expert_tool'
    // 'business_design_expert_tool'
    // 'office_design_expert_tool'
    // Find the object with the matching 'value'
    const option = chat_type_options.find(option => option.value === value);
    // Return the found object or an empty object if not found
    return option || {};
}

var chatTypeOptionsSortByWeight = function(chatTypeOptions) {
    return chatTypeOptions.slice().sort((a, b) => b.weight - a.weight);
}

var getTopActiveModelsByWeight = function(chatTypeOptions, topCount) {
    // 过滤出 active 为 true 的模型
    const activeModels = chatTypeOptions.filter(option => option.active && option.permissions.includes('execute'));

    // 根据 weight 降序排序
    const sortedActiveModels = activeModels.sort((a, b) => b.weight - a.weight);

    // 获取 weight 最大的 topCount 个模型
    return sortedActiveModels.slice(0, topCount);
}

export {
    chatTypeIcons,
    chatTypeSidebarIcons,
    toggleChatTypeActive,
    generateOptionsByCategory,
    findObjFromChatTypeOptionsByValue,
    chatTypeOptionsSortByWeight,
    getTopActiveModelsByWeight,
}