import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'

import ConversationCountUsageCard from '../ConversationCountUsageCard'
import PersonalConversationTable from '../PersonalConversationTable'
import { actionCreators } from '../../store'
import styles from './index.module.css'


const PersonalConversation = () => {
    const [user, setUser] = useState({})
    const dispatch = useDispatch()
    let active_uuid = useSelector(state => state.get('admin').get('active_uuid'))
    let conversation_count_data = useSelector(state => state.get('admin').get('conversation_count_data'))
    let filter_dates = useSelector(state => state.get('admin').get('filter_dates')).toJS()

    useEffect(() => {
        console.log("副作用函数的执行，获取个人 conversation active_uuid = ", active_uuid);
        if (active_uuid) {
            let body = {}

            if (filter_dates.length !== 0) {
                let start_time = filter_dates[0]
                let end_time = filter_dates[1]
                body['start_time'] = start_time
                body['end_time'] = end_time
            }
            body['uuid'] = active_uuid

            dispatch(actionCreators.fetchPersonalConversationInfo(body))
        } else {
            message.info("请先到【对话统计信息】页面指定要查看的用户")
        }
    }, [active_uuid])

    useEffect(() => {
        let d = conversation_count_data.toJS()
        let obj = d.find(item => item.uuid === active_uuid)
        setUser(obj)
    }, [conversation_count_data])

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>
                    个人对话信息
                </div>

                {
                    user ? (
                        <div className={styles.user}>
                            {
                                `${user.username}${user.nickname ? '(' + user.nickname + ')' : ''} 的对话信息`
                            }
                        </div>
                    ) : null
                }
            </div>

            <div className={styles.card_box}>
                {/* <ConversationCountUsageCard /> */}
            </div>

            <div className={styles.table_box}>
                <PersonalConversationTable />
            </div>
        </div >
    )
}

export default PersonalConversation;