import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'


class SideBarNewChatButton extends Component {
    addNewConversation() {
        this.props.handleIsShowExpertSelectAction(true)
        this.props.handleIsShowExpertDetailAction(false)
        this.props.handleExpertRelatedAction()
    }

    render() {
        return (
            <div className={styles.container}>
                <div
                    className={styles.new_butn}
                    onClick={this.addNewConversation.bind(this)}
                >
                    <div className={styles.img}></div>
                    <span className={styles.text}>创建新对话</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowExpertSelectAction(status) {
            dispatch(actionCreators.isShowExpertSelectAction(status))
        },
        handleIsShowExpertDetailAction(status) {
            dispatch(actionCreators.isShowExpertDetailAction(status))
        },
        handleExpertRelatedAction() {
            dispatch(actionCreators.expertRelatedAction())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarNewChatButton)