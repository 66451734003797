import { useState } from 'react'
import { Card, Col, Row, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux'

import styles from './index.module.css'
import KnowledgeBaseGoBack from '../KnowledgeBaseGoBack'


function ModalSlice({ open, setOpen, title, content }) {
    const handleOk = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            title={title}
            onOk={handleOk}
            onCancel={handleOk}
            footer={false}
            className={styles.modal_slice}
        >
            <div className={styles.modal_content}>
                <div className={styles.content_title}>
                    切片内容
                </div>
                <div className={styles.content_box}>
                    {content}
                </div>
            </div>
        </Modal>
    )
}

function KnowledgeBaseContentSlice() {
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    let file_slice_list = useSelector(state => state.get('admin').get('file_slice_list')).toJS()

    const handleClick = (t, c) => {
        setOpen(true)
        setTitle(t)
        setContent(c)
    }

    return (
        <div className={styles.main}>
            <KnowledgeBaseGoBack />

            <Row gutter={16}>
                {
                    file_slice_list && file_slice_list.docs?.map((item, index) => {
                        let t = `${item.file_name} - 切片${index}`
                        return (
                            <Col span={8} style={{marginBottom: '20px'}} key={item.id}>
                                <Card
                                    type="inner"
                                    title={t}
                                    extra={
                                        <a onClick={() => { handleClick(t, item.content) }}>
                                            查看详情
                                        </a>
                                    }
                                >
                                    <div className={styles.slice_card_content}>
                                        { item.content }
                                    </div>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>

            <ModalSlice
                open={open}
                setOpen={setOpen}
                title={title}
                content={content}
            />
        </div>
    )
}

export default KnowledgeBaseContentSlice