import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Divider } from 'antd'

import styles from './index.module.css'
import SideBarNewChatButton from '../SideBarNewChatButton'
import SideBarChatHistoryList from '../SideBarChatHistoryList'
import SideBarChatType from '../SideBarChatType'
import SideExperienceProgressBar from '../SideExperienceProgressBar'


class SideBarChatBox extends Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.new_chat_box}>
                    <SideBarNewChatButton />
                </div>

                <div className={styles.progress_bar}>
                    <SideExperienceProgressBar area={'side'} />
                </div>

                <div className={styles.chat_type_box}>
                    <SideBarChatType />
                </div>

                <div className={styles.divider_box}>
                    <Divider style={{margin: "5px 0 20px 0", backgroundColor: "rgba(203, 209, 216, 0.5)"}} />
                </div>

                <div className={styles.chat_history_box}>
                    <SideBarChatHistoryList />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarChatBox)