import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'antd'

import MessageItem from '../MessageItem'
import styles from './index.module.css'
import { actionCreators } from '../../store'
import { getMessagesFromAllChatData, getSingleConversationFromAllChatData,
    scrollToBottom, isDivScrolledToBottom
 } from '../../../../utils'


class MessageList extends Component {
    constructor(props) {
        super(props);
        this.messagesRef = React.createRef();
    }

    componentDidMount() {
        // 组件挂载时，如果 messagesRef 已存在，则注册 wheel 事件
        if (this.messagesRef.current) {
            this.messagesRef.current.addEventListener('wheel', this.handleWheel)
            scrollToBottom(this.messagesRef.current)
        }
    }

    componentWillUnmount() {
        // 组件将要卸载时，移除 wheel 事件监听
        if (this.messagesRef.current) {
            this.messagesRef.current.removeEventListener('wheel', this.handleWheel)
        }
    }

    handleWheel = (event) => {
        if (isDivScrolledToBottom(this.messagesRef.current)) {
            this.props.handleAllowProgramScrollAction(true)
        } else if (this.props.allow_program_scroll) {
            console.log('Mouse wheel event detected!')
            this.props.handleAllowProgramScrollAction(false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.allow_program_scroll) {
            // 只有在接收到新消息时才滚动
            let old_active_topicid = prevProps.active_topicid
            let current_active_topicid = this.props.active_topicid
            let old_active_date = prevProps.active_date
            let current_active_date = this.props.active_date
            if (old_active_date !== current_active_date || old_active_topicid !== current_active_topicid) {
                scrollToBottom(this.messagesRef.current)
            } else if (old_active_date === current_active_date && old_active_topicid === current_active_topicid) {
                let prev_chat_data = prevProps.all_chat_data.toJS()
                let prevSingleConversation = getSingleConversationFromAllChatData(prev_chat_data, old_active_date, old_active_topicid)

                let current_chat_data = this.props.all_chat_data.toJS()
                let currentSingleConversation = getSingleConversationFromAllChatData(current_chat_data, current_active_date, current_active_topicid)

                if (currentSingleConversation?.data?.length) {
                    let prev_messages_len = prevSingleConversation.data.length
                    let current_messages_len = currentSingleConversation.data.length
                    let prev_text_len = prevSingleConversation.data[prevSingleConversation.data.length - 1]?.text.length
                    let current_text_len = currentSingleConversation.data[currentSingleConversation.data.length - 1]?.text.length
                    let prev_text_extra_img_len = prevSingleConversation.data[prevSingleConversation.data.length - 1]?.text_extra_img.length
                    let current_text_extra_img_len = currentSingleConversation.data[currentSingleConversation.data.length - 1]?.text_extra_img.length
                    let prev_text_extra_search_len = prevSingleConversation.data[prevSingleConversation.data.length - 1]?.text_extra_search.length
                    let current_text_extra_search_len = currentSingleConversation.data[currentSingleConversation.data.length - 1]?.text_extra_search.length
                    let prev_recommended_tools_len = prevSingleConversation.data[prevSingleConversation.data.length - 1]?.recommended_tools.length
                    let current_recommended_tools_len = currentSingleConversation.data[currentSingleConversation.data.length - 1]?.recommended_tools.length

                    if (prev_messages_len !== current_messages_len) {
                        scrollToBottom(this.messagesRef.current)
                    } else if (prev_text_len !== current_text_len) {
                        scrollToBottom(this.messagesRef.current)
                    } else if (prev_text_extra_img_len !== current_text_extra_img_len) {
                        scrollToBottom(this.messagesRef.current)
                    } else if (prev_text_extra_search_len !== current_text_extra_search_len) {
                        scrollToBottom(this.messagesRef.current)
                    } else if (prev_recommended_tools_len !== current_recommended_tools_len) {
                        scrollToBottom(this.messagesRef.current)
                    }
                }
            }
        }
    }

    render() {
        let active_date = this.props.active_date
        let active_topicid = this.props.active_topicid
        let messages = []

        if (active_date !== '' && active_topicid !== '') {
            let all_chat_data = this.props.all_chat_data.toJS()
            messages = getMessagesFromAllChatData(all_chat_data, active_date, active_topicid)
        }

        return (
            <div
                className={styles.content}
                ref={this.messagesRef}
                onClick={this.handleContentClick.bind(this)}
            >
                <div className={styles.item_box}>
                    {
                        messages.map((item, index) => {
                            return (
                                <MessageItem
                                    ItemData={item}
                                    ItemIndex={index}
                                    key={item.topic_message_id}
                                    TypeDone={
                                        this.props.is_thinking && messages.length === index + 1 && item.status === 'pass' ? true : false
                                    }
                                />
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    handleContentClick(e) {
        if (e.target.closest('.msg_images_id_like')) {
            let div = e.target.closest('.msg_images_id_like')
            let data_id = div.dataset.id; // dataset 中的 'id' 对应于 'data-id' 属性
            let data_message_id = div.dataset.message_id;
            let data_url = div.dataset.url;
            let data_score = div.dataset.score;
            let data_index_msg = div.dataset.index_msg

            // 检查 score 是否为合法的整数
            if (data_score && !isNaN(data_score) && Number.isInteger(Number(data_score))) {
                // 转换为整数
                data_score = parseInt(data_score, 10);
                if (data_score <= 0) {
                    data_score = 1
                } else {
                    data_score = -1
                }
            } else {
                data_score = 1
            }

            console.log(`data_id = (${data_id})`);
            console.log(`data_message_id = (${data_message_id})`);
            console.log(`data_url = (${data_url})`);
            console.log(`data_score = (${data_score})`);

            this.props.handleMessageImageScoreLikeAction(div, data_id, data_url, data_score, data_message_id, data_index_msg)
        } else if (e.target.closest('.msg_images_id_overlay')) {
            console.log("msg_images_id_overlay");
            // 访问 div 元素的 data-url 属性
            let div = e.target.closest('.msg_images_id_overlay')
            const imageUrl = div.dataset.url; // dataset 中的 'url' 对应于 'data-url' 属性
            this.props.handleMessageItemImagePathAction(imageUrl)
            this.props.handleMessageImageVisibleAction(true)
        // } else if (e.target.closest('.response_extra_suggestion_item')) {
        //     let div = e.target.closest('.response_extra_suggestion_item')
        //     const text = div.dataset.info; // dataset 中的 'info' 对应于 'data-info' 属性
        //     if (text.trim() !== '' && !this.props.is_thinking) {
        //         this.props.handleSendAskAction(text)
        //     }
        } else if (e.target.closest('.msg_images_id')) {
            console.log("msg_images_id");
            let div = e.target.closest('.msg_images_id')
            const imageUrl = div.dataset.url; // dataset 中的 'url' 对应于 'data-url' 属性
            this.props.handleMessageItemImagePathAction(imageUrl)
            this.props.handleMessageImageVisibleAction(true)
        } else if (e.target.closest('.msg_search_result_title') || e.target.closest('.msg_search_result_title_icon')) {
            let div;
            if (e.target.closest('.msg_search_result_title')) {
              div = e.target.closest('.msg_search_result_title');
            } else if (e.target.closest('.msg_search_result_title_icon')) {
              div = e.target.closest('.msg_search_result_title_icon');
            }

            if (div) {
                // 找到爷爷元素
                let grandparent = div.parentNode.parentNode;

                // 检查是否存在特定的class
                if (grandparent.classList.contains('msg_search_result_container_shrink')) {
                    // 如果存在
                    grandparent.classList.remove('msg_search_result_container_shrink');
                } else {
                    // 如果不存在
                    grandparent.classList.add('msg_search_result_container_shrink');
                }
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        is_thinking: state.get('home').get('is_thinking'),
        all_chat_data: state.get('home').get('all_chat_data'),
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
        allow_program_scroll: state.get('home').get('allow_program_scroll'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleAllChatDataAction(data) {
            dispatch(actionCreators.allChatDataAction(data))
        },
        handleMessageImageVisibleAction(status) {
            dispatch(actionCreators.messageImageVisibleAction(status))
        },
        handleMessageItemImagePathAction(path) {
            dispatch(actionCreators.messageItemImagePathAction(path))
        },
        handleSendAskAction(value) {
            dispatch(actionCreators.sendAskAction(value))
        },
        handleAllowProgramScrollAction(status) {
            dispatch(actionCreators.allowProgramScrollAction(status))
        },
        handleMessageImageScoreLikeAction(element, id, url, score, message_id, index_msg) {
            dispatch(actionCreators.messageImageScoreLikeAction(element, id, url, score, message_id, index_msg))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageList)