import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'
import mila from 'markdown-it-link-attributes'
import mdKatex from '@traptitech/markdown-it-katex'
import { message, Tooltip, Popover } from 'antd';

import { actionCreators } from '../../store'
import { findObjFromChatTypeOptionsByValue } from '../../../../utils'
import styles from './index.module.css'
import userAvatar from '../../../../statics/avatar.png'
import userWomanAvatar from '../../../../statics/avatar_woman.png'
// import aiAvatar from '../../../../statics/chatgpt.png'
import aiAvatar from '../../../../statics/ai_link_stone.gif'
import loadingImg from '../../../../statics/loading.gif'


function ItemStar({ startNum }) {
    // 创建一个包含 5 个星星的数组，其中高亮星星的数量等于 startNum
    const stars = [];
    for (let i = 0; i < 5; i++) {
        if (i < startNum) {
            stars.push(<div className={styles.recommend_start_active} key={i}></div>)
        } else {
            stars.push(<div className={styles.recommend_start} key={i}></div>)
        }
    }

    return (
        <div className={styles.popover_item_img_box}>
            { stars }
        </div>
    )
}

function CustomPopoverItemList({ recommended_tools, chat_type_change, chat_type_options }) {
    return (
        <div className={styles.popover_box}>
            {
                recommended_tools.map((item, index) => {
                    let start_num = 5
                    if (index === 0) {
                        start_num = 5
                    } else if (index === 1) {
                        start_num = 3
                    } else {
                        start_num = 2
                    }

                    return (
                        <div
                            className={styles.popover_item}
                            key={item}
                            onClick={() => {chat_type_change(findObjFromChatTypeOptionsByValue(item, chat_type_options)?.value)}}
                        >
                            <div className={styles.popover_item_text}>
                                { findObjFromChatTypeOptionsByValue(item, chat_type_options)?.label }
                            </div>
                            {
                                <ItemStar
                                    startNum={ start_num }
                                />
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

function CustomPopover({ recommended_tools, chat_type_change, chat_type_options }) {
    let [open, setOpen] = useState(false)

    return (
        <Popover
            content={() => {
                return (
                    <CustomPopoverItemList
                        recommended_tools={recommended_tools}
                        chat_type_change={chat_type_change}
                        chat_type_options={chat_type_options}
                    />
                )
            }}
            placement="rightBottom"
            open={open}
            trigger="click"
            onOpenChange={() => { setOpen(!open) }}
            arrow={false}
            overlayClassName={"custom_popover_message"}
        >
            <div className={styles.recommend_box}>
                <div className={styles.recommend_butn_box}>
                    <div className={styles.recommend_butn_text}>灵石推荐专家助手</div>
                    <div className={styles.recommend_butn_icon}></div>
                </div>
            </div>
        </Popover>
    )
}

class MessageItem extends Component {
    chatAvatar({role, icon, style}) {
        return (
            <div
                className={styles.avatar}
                style={{
                    ...style
                }}
            >
                <img src={icon} alt="" className={`${role === 'user' ? styles.img : styles.ai_img}`} />
            </div>
        )
    }

    renderMdHtml(value) {
        function highlightBlock(str, lang, code) {
            return `<pre class="code-block-wrapper"><div class="code-block-header"><span class="code-block-header__lang">${lang}</span><span class="code-block-header__copy">复制代码</span></div><code class="hljs code-block-body ${lang}">${str}</code></pre>`
        }

        const mdi = new MarkdownIt({
            html: true,
            linkify: true,
            highlight(code, language) {
              const validLang = !!(language && hljs.getLanguage(language))
              if (validLang) {
                const lang = language ?? ''
                return highlightBlock(hljs.highlight(code, { language: lang }).value, lang, code)
              }
              return highlightBlock(hljs.highlightAuto(code).value, '', code)
            }
        })

        mdi.use(mila, { attrs: { target: '_blank', rel: 'noopener' } })
        mdi.use(mdKatex, { blockClass: 'katex-block', errorColor: ' #cc0000', output: 'mathml' })

        return mdi.render(value)
    }

    renderText(role, text, text_extra_img, text_extra_search, status) {
        text = text + text_extra_img + text_extra_search
        let value = text || ''
        if (role === 'user') {
            return (
                <div className="markdown-body">
                    { value }
                </div>
            )
        } else if (status === 'loading' && text === '' && role === 'ai') {
            return <img src={loadingImg} alt="loading..." className={styles.loading_img} />
        } else {
            let render_md_html = this.renderMdHtml(value)
            return (
                <div
                    className="markdown-body"
                    dangerouslySetInnerHTML={{
                        __html: render_md_html
                    }}
                >
                </div>
            )
        }
    }

    render() {
        let item = this.props.ItemData
        let chat_type_options = this.props.chat_type_options.toJS()
        let user_data = this.props.user_data.toJS()
        // if (this.props.TypeDone) {
        //     this.props.handleIsThinkingAction(false)
        // }

        let user_avatar = user_data.avatar ? user_data.avatar : (user_data.gender === 'woman' ? userWomanAvatar : userAvatar)

        let is_show_recommend = false
        if (item.recommended_tools.length > 0) {
            is_show_recommend = this.shouldRenderCustomPopover(item, chat_type_options)
        }

        return (
            <div className={styles.container}>
                {
                    this.chatAvatar({
                        role: item.role,
                        icon: item.role === 'user' ? user_avatar : aiAvatar,
                        style: { marginRight: 12 },
                    })
                }

                <div className={`${styles.content} ${this.props.is_thinking && item.status === 'loading' ? styles.content_thinking : ''}`}>
                    {/* <span
                        className={styles.content_time}
                    >
                        {item.updated_at}
                    </span> */}
                    <div
                        className={`
                            ${styles.content_text}
                            ${item.role === 'user' ? styles.content_left : styles.content_right}
                            ${is_show_recommend ? styles.content_recommend : ''}
                        `}
                    >
                        {
                            this.renderText(item.role, item.text, item.text_extra_img, item.text_extra_search, item.status)
                        }
                        {
                            item.role === 'user' || item.status === 'loading' ? (
                                // <div className={styles.content_text_butns}>
                                //     <div className={styles.butn} onClick={() => {this.handleContentTextButn('edit', item.message_id)}}>
                                //         <img src={editImg} className={styles.butn_img} />
                                //     </div>
                                // </div>
                                null
                            ) : (
                                <div className={styles.content_text_butns}>
                                    <Tooltip placement="bottom" title={"点赞"}>
                                        <div
                                            className={`${styles.butn} ${item.message_score === 1 ? styles.butn_active : ''}`}
                                            onClick={() => {this.handleContentTextButn('like', item.message_id, item.message_score)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.like_img}`} data-alt="点赞"></div>
                                        </div>
                                    </Tooltip>

                                    <Tooltip placement="bottom" title={"点踩"}>
                                        <div
                                            className={`${styles.butn} ${item.message_score === 0 ? styles.butn_active : ''}`}
                                            onClick={() => {this.handleContentTextButn('negative', item.message_id, item.message_score)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.negative_img}`} data-alt="点踩"></div>
                                        </div>
                                    </Tooltip>

                                    <Tooltip placement="bottom" title={"重新生成"}>
                                        <div
                                            className={styles.butn}
                                            onClick={() => {this.handleContentTextButn('regenerate', item.message_id, '', this.props.ItemIndex)}}
                                        >
                                            <div className={`${styles.butn_img} ${styles.regenerate_img}`} data-alt="重新生成"></div>
                                        </div>
                                    </Tooltip>
                                </div>
                            )
                        }
                        {
                            is_show_recommend ? (
                                <CustomPopover
                                    recommended_tools={item.recommended_tools.slice(0, 3)}
                                    chat_type_change={this.handleChatTypeChange.bind(this)}
                                    chat_type_options={chat_type_options}
                                />
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    handleContentTextButn(type, message_id, message_score, index=-1) {
        if (this.props.is_thinking) {
            return;
        }

        if (type === 'edit') {
            message.info("暂时还不支持")
        } else if (type === 'like') {
            if (message_score === 1) {
                this.props.handlePostMessageScoreAction(message_id, -1, this.props.active_date, this.props.active_topicid)
            } else {
                this.props.handlePostMessageScoreAction(message_id, 1, this.props.active_date, this.props.active_topicid)
            }
        } else if (type === 'negative') {
            if (message_score === 0) {
                this.props.handlePostMessageScoreAction(message_id, -1, this.props.active_date, this.props.active_topicid)
            } else {
                this.props.handlePostMessageScoreAction(message_id, 0, this.props.active_date, this.props.active_topicid)
            }
        } else if (type === 'regenerate') {
            this.props.handlePostMessageRegenerateAction(message_id, index, this.props.active_date, this.props.active_topicid)
        }
    }

    shouldRenderCustomPopover(item, chat_type_options) {
        const isToolValid = item.role === 'ai' &&
            this.props.chat_type !== 'link_stone' &&
            item.recommended_tools.length > 0 &&
            findObjFromChatTypeOptionsByValue(item.recommended_tools[0], chat_type_options)?.value !== this.props.chat_type

        return isToolValid
    }

    handleChatTypeChange(value) {
        this.props.handleChatTypeChangeAction(value)
    }
}

const mapStateToProps = (state) => {
    return {
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
        is_thinking: state.get('home').get('is_thinking'),
        chat_type: state.get('home').get('chat_type'),
        chat_type_options: state.get('home').get('chat_type_options'),
        user_data: state.get('login').get('user_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsThinkingAction(status) {
            dispatch(actionCreators.isThinkingAction(status))
        },
        handlePostMessageScoreAction(message_id, message_score, active_date, active_topicid) {
            dispatch(actionCreators.postMessageScoreAction(message_id, message_score, active_date, active_topicid))
        },
        handlePostMessageRegenerateAction(message_id, index, active_date, active_topicid) {
            dispatch(actionCreators.postMessageRegenerateAction(message_id, index, active_date, active_topicid))
        },
        handleChatTypeChangeAction(value) {
            dispatch(actionCreators.chatTypeChangeAction(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageItem)