import { useState } from 'react'
import { Progress } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'

import styles from './index.module.css'
import useUserLevel from '../../../../utils/useUserLevel'
import lightningImg from '../../../../statics/experience_lightning_charge.png'
import ExperienceValueScrolling from '../ExperienceValueScrolling'
import { experienceIcons } from '../../../../utils'

function SideExperienceProgressBar({area}) {
    const current_experience = useSelector(state => state.get('home').get('current_experience'))
    const { level, totalExperience } = useUserLevel(current_experience);
    let lv_n_icon = experienceIcons[level - 1]

    return (
        <div className={`${styles.main} ${area === 'side' ? '' : styles.main_evenly}`}>
            {
                area === 'side' ? (
                    <img src={lv_n_icon} className={styles.lv_n_icon} alt="icon" />
                ) : null
            }

            {
                area === 'side' ? (
                    <div className={styles.grade}>Lv{level}</div>
                ) : (
                    <img src={lightningImg} className={styles.img} />
                )
            }

            <div className={styles.progress_box}>
                <div
                    className={styles.progress_trail}
                    style={area === 'side' ? {} : {height: '14px', borderRadius: '8px'}}
                >
                    <div
                        className={styles.progress_stroke}
                        style={
                            {width: `${(current_experience / totalExperience) * 100}%`, borderRadius: `${area === 'side' ? '' : '8px'}`}
                        }
                    >
                        <div className={styles.progress_stroke_white}></div>
                    </div>
                </div>


                {/* <Progress
                    percent={}
                    showInfo={false}
                    strokeColor={'rgba(60, 170, 230, 1)'}
                    trailColor={"rgba(229, 229, 229, 1)"}
                    size={area === 'side' ? {height: '8px'} : {height: '14px'}}
                /> */}
            </div>
            <div className={styles.experience}>
                <ExperienceValueScrolling />
            </div>
        </div>
    )
}

export default SideExperienceProgressBar