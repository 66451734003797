import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Spin } from 'antd'
import { Redirect } from 'react-router-dom'

import styles from './index.module.css'
import PageCard from './components/PageCard'
import { actionCreators } from './store'
import { actionCreators as homeActionCreators } from '../home/store'
import wechatBgArrow from '../../statics/wechat_container_arrow.gif'
import { getUrlVars, getOriginWithExplicitPort } from '../../utils'


class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEnterpriseWeChat: false, // 默认不是企业微信内嵌浏览器
            userAgent: 'test',
        }
    }

    componentDidMount() {
        this.detectEnvironment()
    }

    detectEnvironment() {
        const userAgent = navigator.userAgent;
        // 检查是否为企业微信内嵌浏览器
        const isEnterpriseWeChat = userAgent.match(/wxwork/i);

        this.setState({ isEnterpriseWeChat: !!isEnterpriseWeChat, userAgent: userAgent }, () => {
            // 状态更新后的回调
            if (this.state.isEnterpriseWeChat) {
                console.log("企业微信内部");
                this.props.handleHasLoginAction(false);
                localStorage.removeItem("loginState");
                // 现在状态已更新，添加事件监听器
                window.addEventListener('wheel', this.handleWheel, { passive: false });
            } else {
                console.log("企业微信外部");
                this.handleLoginStatus();
            }
        })
    }

    componentWillUnmount() {
        if (this.state.isEnterpriseWeChat) {
            // 组件将要卸载时移除滚轮事件监听
            window.removeEventListener('wheel', this.handleWheel, { passive: false });
        }
    }

    handleWheel = (event) => {
        if (event.ctrlKey) {
            console.log("禁止缩放");
            // 阻止默认的缩放行为
            event.preventDefault();
        }
    }

    handleLoginStatus() {
        let company_route = ''
        let what_company = sessionStorage.getItem('what_company')
        sessionStorage.removeItem('what_company')

        if (what_company === 'jietu') {
            company_route = '/account/jietu_qywx_scan_login'
        } else {
            company_route = '/account/jieen_qywx_scan_login'
        }

        if (getUrlVars().code) {
            // 判断如果是企业微信登录，则跳转，显示 loading 以及请求 authen_token
            this.props.handleIsLoginLoadingAction(true)

            const url = getOriginWithExplicitPort() + `/api${company_route}?code=${getUrlVars().code}&state=${getUrlVars().code}`
            // 发起一个GET请求到指定的URL
            fetch(url)
                .then(response => {
                    console.log("response = ", response);
                // 首先检查响应的状态码是否表明请求成功
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    } else {
                        // 然后解析 JSON 格式的响应体
                        return response.json();
                    }
                })
                .then(data => {
                    // 处理解析后的数据
                    console.log(data);
                    let d = data.data
                    this.props.handleUserDataAction(d)
                    this.props.handleIsLoginLoadingAction(false)
                    this.props.handleHasLoginAction(true)
                    if (d.is_first_login) {
                        // 首次登录
                        this.props.handleIsShowUserResearch(true)
                    } else if (d.has_details === false) {
                        this.props.handleIsShowUserResearch(true)
                    }

                    let location = {
                        pathname: '/home',
                        search: '',  // 确保 search 为空字符串，这样就不会有查询参数
                        state: {}    // 如果需要传递 state 对象给新路由，可以在这里定义
                    };
                    this.props.history.push(location)
                })
                .catch(error => {
                    // 处理请求过程中发生的任何错误
                    console.error('登录报错:', error);
                    this.props.handleIsLoginLoadingAction(false)
                    this.props.history.push({ pathname: "/", search: '', state: {} })
                });
        }
    }

    render() {
        if (!this.state.isEnterpriseWeChat) {
            if (this.props.is_login_loading) {
                return (
                    <div className={styles.container_loading}>
                        <Spin tip="Loading..."></Spin>
                    </div>
                )
            }

            if (this.props.has_login) {
                return <Redirect to="/home" />
            }

            return (
                <div className={styles.container}>
                    <PageCard />
                </div>
            )
        } else {
            return (
                <div className={`${styles.wechat_container} ${styles.wechat_bg_img}`}>
                    {/* <div>userAgent = {this.state.userAgent}</div> */}
                    <img src={wechatBgArrow} className={styles.wechat_bg_arrow} alt="wechat" />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        has_login: state.get('login').get('has_login'),
        is_login_loading: state.get('login').get('is_login_loading'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsLoginLoadingAction(status) {
            dispatch(actionCreators.isLoginLoadingAction(status))
        },
        handleUserDataAction(data) {
            dispatch(actionCreators.userDataAction(data))
        },
        handleHasLoginAction(status) {
            dispatch(actionCreators.hasLoginAction(status))
        },
        handleIsShowUserResearch(status) {
            dispatch(homeActionCreators.isShowUserResearchAction(status))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)