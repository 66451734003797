import styles from './index.module.css'
import AddToHomeScreenButton from '../AddToHomeScreenButton'


function ChatMessageFooter() {
    return (
        <div className={styles.main}>
            <div className={styles.item}>
                <AddToHomeScreenButton />
            </div>

        </div>
    )
}

export default ChatMessageFooter