import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import styles from './index.module.css'
import { actionCreators } from '../../store'
import wxImg from '../../../../statics/wechat.png'


class WXLogin extends Component {
    handleRedirect() {
        console.log("微信登录");
    }

    render() {
        return (
            <div
                className={styles.container}
                title={"微信登录"}
                onClick={this.handleRedirect.bind(this)}
            >
                <img src={wxImg} className={styles.img} />
                <span className={styles.text}>使用微信登录</span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // email: state.get('login').get('email'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // handleSetEmailAction(value) {
        //     dispatch(actionCreators.setEmailAction(value))
        // },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WXLogin))