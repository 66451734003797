import * as actionTypes from './actionTypes'
import {
    convertUTCToLocalTime, convertGender, convertCity, convertOccupation,
    convertAge, convertModelName, convertModelMenu, fetchWithCookieRefresh,
    convertKnowledgeBaseConfigToOptions, getCurrentTodayFormattedTime,
    getStartOfWeekDate, getStartOfMonth, getStartOfToday, transformCategorizeData,
} from '../../../utils'
import { message } from 'antd'


export const setActiveNavbarAction = (value) => {
    return {
        type: actionTypes.SET_ACTIVE_NAVBAR_ACTION,
        value: value,
    }
}

export const fetchUserStatisticsInfo = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/admin/get_users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // "start_time": "2024-06-21T07:42:19.119Z",
                // "end_time": "2024-06-21T07:42:19.119Z"
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/get_users data = ", data);

            // 当你从后端或其他地方获取到数据时，给每个数据项添加一个key属性
            const temp_data = data.data.map(item => ({
                ...item,
                registered_on: convertUTCToLocalTime(item.registered_on),
                last_login: convertUTCToLocalTime(item.last_login),
                gender: convertGender(item.gender),
                city: convertCity(item.city),
                occupation: convertOccupation(item.occupation),
                age: convertAge(item.age),
                key: item.id, // 确保key是唯一的，这里使用了数据项的id字段
            }));

            dispatch(setUserStatisticsDataAction(temp_data))
        })
        .catch(error => {
            console.log("/api/admin/get_users error = ", error);
        })
    }
}

export const getUserStatisticsTodayInfo = () => {
    return (dispatch, getState) => {
        let start_time = getCurrentTodayFormattedTime()
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        fetchWithCookieRefresh('/api/admin/get_active_info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "start_time": start_time,
                "timezone": timezone
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/get_active_info today data = ", data);
            dispatch(setUserStatisticsTodayLoginNum(data.data?.uuids?.length))
        })
        .catch(error => {
            console.log("/api/admin/get_active_info today error = ", error);
        })
    }
}

export const getUserStatisticsWeekInfo = () => {
    return (dispatch, getState) => {
        let start_time = getStartOfWeekDate()
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        fetchWithCookieRefresh('/api/admin/get_active_info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "start_time": start_time,
                "timezone": timezone
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/get_active_info week data = ", data);
            dispatch(setUserStatisticsWeekLoginNum(data.data?.uuids?.length))
        })
        .catch(error => {
            console.log("/api/admin/get_active_info week error = ", error);
        })
    }
}

export const setUserStatisticsDataAction = (data) => ({
    type: actionTypes.SET_USER_STATISTICS_DATA_ACTION,
    data: data,
})

export const setUserStatisticsTodayLoginNum = (value) => ({
    type: actionTypes.SET_USER_STATISTICS_TODAY_LOGIN_NUM,
    value: value
})

export const setUserStatisticsWeekLoginNum = (value) => ({
    type: actionTypes.SET_USER_STATISTICS_WEEK_LOGIN_NUM,
    value: value
})

export const fetchConversationCountInfo = (body) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/get_query_count', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                body
                // {
                // "start_time": "2024-06-21T07:42:19.119Z",
                // "end_time": "2024-06-21T07:42:19.119Z"
                // }
            ),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/get_query_count data = ", data);
            const temp_data = data.data
            console.log("temp_data = ", temp_data);
            dispatch(setConversationCountDataAction(temp_data))
            return true
        })
        .catch(error => {
            console.log("/api/admin/get_query_count error = ", error);
        })
    }
}

export const setConversationCountDataAction = (data) => ({
    type: actionTypes.SET_CONVERSATION_COUNT_DATA_ACTION,
    data: data,
})

export const setActionUuidAction = (value) => ({
    type: actionTypes.SET_ACTIVE_UUID_ACTION,
    value: value,
})

export const fetchPersonalConversationInfo = (body) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/admin/get_user_messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                body
                // {
                // "uuid": uuid,
                // "start_time": "2024-06-21T07:42:19.119Z",
                // "end_time": "2024-06-21T07:42:19.119Z"
                // }
            ),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/get_user_messages data = ", data);
            let temp_data = data.data.query.concat(data.data.re_query)
            let chat_type_options = getState().get("home").get("chat_type_options").toJS()

            temp_data = temp_data.map(item => ({
                ...item,
                query_at: convertUTCToLocalTime(item.query_at),
                chat_type: convertModelName(chat_type_options, item.chat_type),
            }))

            dispatch(setPersonalConversationDataAction(temp_data))
        })
        .catch(error => {
            console.log("/api/admin/get_user_messages error = ", error);
        })
    }
}

export const setPersonalConversationDataAction = (data) => ({
    type: actionTypes.SET_PERSONAL_CONVERSATION_DATA_ACTION,
    data: data,
})

export const fetchKnowledgeBaseList = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/list', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/list data = ", data);
            let temp_data = data.data
            let menu = convertModelMenu(temp_data)

            if (menu.length > 0) {
                let current_active_model = getState().get("admin").get("active_model")
                let obj = menu.find(item => item.key === current_active_model)
                console.log("fetchKnowledgeBaseList obj = ", obj);
                if (obj) {
                    console.log("存在之前的 active_model");
                } else {
                    let model_key = menu[0].key
                    let model_name = menu[0].label
                    dispatch(setActiveModelAction(model_key, model_name))
                }
            }
            dispatch(setKnowledgeBaseMenuAction(menu))
            dispatch(setKnowledgeBaseListAction(temp_data))
        })
        .catch(error => {
            console.log("/api/knowledge_base/list error = ", error);
        })
    }
}

export const setKnowledgeBaseMenuAction = (data) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_MENU_ACTION,
    data: data,
})

export const setKnowledgeBaseListAction = (data) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_LIST_ACTION,
    data: data,
})

export const setActiveModelAction = (key, name) => ({
    type: actionTypes.SET_ACTIVE_MODEL_ACTION,
    model_key: key,
    model_name: name,
})

export const createKnowledgeBase = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/create', {
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/create data = ", data);
            // 重新获取一遍知识库列表
            dispatch(fetchKnowledgeBaseList())
            message.success(data.msg)
            dispatch(setKnowledgeBaseShowContentAction(''))
            return true
        })
        .catch(error => {
            console.log("/api/knowledge_base/create error = ", error);
            message.error(error.message)
        })
    }
}

export const deleteKnowledgeBase = (name) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/delete data = ", data);
            // 重新获取一遍知识库列表
            dispatch(fetchKnowledgeBaseList())
            dispatch(setKnowledgeBaseShowContentAction(''))
            message.success(data.msg)
        })
        .catch(error => {
            console.log("/api/knowledge_base/delete error = ", error);
            message.error(error.message)
        })
    }
}

export const fetchSliceDetails = (knowledge_base_name, file_name) => {
    let args = `?knowledge_base_name=${knowledge_base_name}&file_name=${file_name}`
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/file/document/list' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/file/document/list data = ", data);
            dispatch(setFileSliceListAction(data.data))
        })
        .catch(error => {
            console.log("/api/knowledge_base/file/document/list error = ", error);
            message.error(error.message)
        })
    }
}

export const setKnowledgeBaseShowContentAction = (value) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_SHOW_CONTENT,
    value: value,
})

export const setFileSliceListAction = (data) => ({
    type: actionTypes.SET_FILE_SLIT_LIST_ACTION,
    data: data,
})

export const fetchUploadFiles = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/files/upload', {
            method: 'POST',
            body: formData,
        }, 1, 1000*60000)
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/files/upload data = ", data);
            let fail_data = Object.values(data.data.fail)
            console.log("fail_data = ", fail_data);
            fail_data.forEach((item) => {
                message.error(item)
            })

            let success_data = Object.values(data.data.success)
            console.log("success_data = ", success_data);
            success_data.forEach((item) => {
                message.success(item)
            })
            dispatch(fetchKnowledgeBaseList())
            dispatch(setKnowledgeBaseShowContentAction(''))

            return data
        })
        .catch(error => {
            console.log("/api/knowledge_base/files/upload error = ", error);
            message.error(error.message)
        })
    }
}

export const deleteKnowledgeBaseFile = (knowledge_base_name, file_name) => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/knowledge_base/file/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "kb_name": knowledge_base_name,
                "file_name": file_name,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/file/delete data = ", data);
            // 重新获取一遍知识库列表
            dispatch(fetchKnowledgeBaseList())
            message.success(data.msg)
        })
        .catch(error => {
            console.log("/api/knowledge_base/file/delete error = ", error);
            message.error(error.message)
        })
    }
}

export const fetchSingleKnowledgeBaseList = (active_model) => {
    return (dispatch, getState) => {
        let knowledge_base_menu = getState().get("admin").get("knowledge_base_menu").toJS()
        let obj = knowledge_base_menu.find(item => item.key === active_model)
        console.log("obj = ", obj);
        return fetchWithCookieRefresh('/api/knowledge_base/files/upload/status?knowledge_base_name=' + obj.label, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/files/upload/status data = ", data);
            let temp_data = data.data
            let knowledge_base_list = getState().get("admin").get("knowledge_base_list").toJS()
            let l = knowledge_base_list.map(item => item.collection === active_model ? temp_data : item);
            dispatch(setKnowledgeBaseListAction(l))
        })
        .catch(error => {
            console.log("/api/knowledge_base/files/upload/status error = ", error);
        })
    }
}

export const fetchUploadImageFiles = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/pictures/upload', {
            method: 'POST',
            body: formData,
        }, 1, 1000*60000)
        .then(response => {
            if (!response.ok) {
                return response.json().then(errorData => {
                    // 此处可以获取到详细的错误信息，并抛出错误
                    const error = new Error("请求错误");
                    error.data = errorData; // 将后端响应的错误信息添加到error对象上
                    throw error;
                });
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/pictures/upload data = ", data);
            message.success("图片上传成功！")
            dispatch(fetchKnowledgeBaseList())
            dispatch(setKnowledgeBaseShowContentAction(''))

            return data
        })
        .catch(error => {
            console.log("/api/knowledge_base/pictures/upload error = ", error);
            console.log("/api/knowledge_base/pictures/upload error = ", error.message);
            console.log("/api/knowledge_base/pictures/upload error = ", error.data);
            if (error.data) {
                message.error(error.data.detail)
            } else {
                message.error(error.message)
            }
        })
    }
}

export const fetchVersionUpdateList = () => {
    return (dispatch, getState) => {
        fetchWithCookieRefresh('/api/admin/list_release_record', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/list_release_record data = ", data);
            let temp_data = data.data.release_record
            dispatch(setVersionUpdateListAction(temp_data))
        })
        .catch(error => {
            console.log("/api/admin/list_release_record error = ", error);
        })
    }
}

export const setVersionUpdateListAction = (data) => ({
    type: actionTypes.SET_VERSION_UPDATE_LIST_ACTION,
    data: data,
})

export const isShowVersionUpdateImage = (status) => ({
    type: actionTypes.IS_SHOW_VERSION_UPDATE_IMAGE,
    status: status,
})

export const showVersionUpdateImageId = (value) => ({
    type: actionTypes.SHOW_VERSION_UPDATE_IMAGE_ID,
    value: value,
})

export const createVersionUpdate = (formData) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/pre_publish_release', {
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/pre_publish_release data = ", data);
            message.success(data.msg)
            dispatch(fetchVersionUpdateList())
            return data
        })
        .catch(error => {
            console.log("/api/admin/pre_publish_release error = ", error);
            message.error(error.message)
        })
    }
}

export const getUsersPermission = () => {
    return (dispatch, getState) => {
        // 获取所有的用户权限信息
        return fetchWithCookieRefresh('/api/rbac/get_users_permissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/get_users_permissions data = ", data);
            dispatch(setPermissionUserListAction(data.data))
        })
        .catch(error => {
            console.log("/api/rbac/get_users_permissions error = ", error);
            message.error(error.message)
        })
    }
}

export const setPermissionUserListAction = (data) => ({
    type: actionTypes.SET_PERMISSION_USER_LIST_ACTION,
    data: data,
})

export const setPermissionUserPageAction = (value) => ({
    type: actionTypes.SET_PERMISSION_USER_PAGE_ACTION,
    value: value,
})

export const setPermissionUserUuidAction = (value) => ({
    type: actionTypes.SET_PERMISSION_USER_UUID_ACTION,
    value: value,
})

export const getGroupsPermissions = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_groups_permissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/get_groups_permissions data = ", data);
            dispatch(setPermissionGroupListAction(data.data))
        })
        .catch(error => {
            console.log("/api/rbac/get_groups_permissions error = ", error);
            message.error(error.message)
        })
    }
}

export const setPermissionGroupListAction = (data) => ({
    type: actionTypes.SET_PERMISSION_GROUP_LIST_ACTION,
    data: data,
})

export const setPermissionGroupPageAction = (value) => ({
    type: actionTypes.SET_PERMISSION_GROUP_PAGE_ACTION,
    value: value,
})

export const setPermissionGroupIdAction = (value) => ({
    type: actionTypes.SET_PERMISSION_GROUP_NAME_ACTION,
    value: value,
})

export const updateUserGroups = (uuid, groups) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_user_groups', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "uuid": uuid,
                "groups": groups
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/update_user_groups data = ", data);
            message.success(data.msg)
            dispatch(getUserGroups(uuid))
        })
        .catch(error => {
            console.log("/api/rbac/update_user_groups error = ", error);
        })
    }
}

export const updateUserPermissions = (uuid, permissions) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_user_permissions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "uuid": uuid,
                "permissions": permissions
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/update_user_permissions data = ", data);
            message.success(data.msg)
            dispatch(getUserPermissions(uuid))
        })
        .catch(error => {
            console.log("/api/rbac/update_user_permissions error = ", error);
        })
    }
}


export const updateGroupUsers = (name, uuids) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_group_users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "uuids": uuids
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/update_group_users data = ", data);
            message.success(data.msg)
            dispatch(getGroupUsers(name))
        })
        .catch(error => {
            console.log("/api/rbac/update_group_users error = ", error);
        })
    }
}

export const updateGroupPermissions = (name, permissions) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/update_group_permissions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "permissions": permissions
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/update_group_permissions data = ", data);
            message.success(data.msg)
            dispatch(getGroupPermissions(name))
        })
        .catch(error => {
            console.log("/api/rbac/update_group_permissions error = ", error);
        })
    }
}

export const getUserGroups = (uuid) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_user_groups?uuid=' + uuid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/get_user_groups data = ", data);
            let user_group = data.data?.user_group || []
            let permission_user_list = getState().get("admin").get("permission_user_list").toJS()
            for (let item of permission_user_list) {
                if (item.uuid === uuid) {
                    item.groups = user_group
                    break
                }
            }
            dispatch(setPermissionUserListAction(permission_user_list))
        })
        .catch(error => {
            console.log("/api/rbac/get_user_groups error = ", error);
            message.error(error.message)
        })
    }
}

export const getUserPermissions = (uuid) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_user_permissions?uuid=' + uuid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/get_user_permissions data = ", data);
            let user_permissions = data.data
            let permission_user_list = getState().get("admin").get("permission_user_list").toJS()
            for (let item of permission_user_list) {
                if (item.uuid === uuid) {
                    item.permissions = user_permissions
                    break
                }
            }
            dispatch(setPermissionUserListAction(permission_user_list))
        })
        .catch(error => {
            console.log("/api/rbac/get_user_permissions error = ", error);
            message.error(error.message)
        })
    }
}

export const getGroupUsers = (name) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_group_users?group_name=' + name, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/get_group_users data = ", data);
            let group_user = data.data.group_user
            let permission_group_list = getState().get("admin").get("permission_group_list").toJS()
            for (let item of permission_group_list) {
                if (item.name === name) {
                    item.users = group_user
                    break
                }
            }
            dispatch(setPermissionGroupListAction(permission_group_list))
        })
        .catch(error => {
            console.log("/api/rbac/get_group_users error = ", error);
            message.error(error.message)
        })
    }
}

export const getGroupPermissions = (name) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/get_group_permissions?group_name=' + name, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/get_group_permissions data = ", data);
            let permissions = data.data
            let permission_group_list = getState().get("admin").get("permission_group_list").toJS()
            for (let item of permission_group_list) {
                if (item.name === name) {
                    item.permissions = permissions
                    break
                }
            }
            dispatch(setPermissionGroupListAction(permission_group_list))
        })
        .catch(error => {
            console.log("/api/rbac/get_group_permissions error = ", error);
            message.error(error.message)
        })
    }
}


export const createGroup = (name, description) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/rbac/create_group', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "description": description,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/rbac/create_group data = ", data);
            message.success(data.msg)
            dispatch(getGroupsPermissions())
            return data
        })
        .catch(error => {
            console.log("/api/rbac/create_group error = ", error);
            message.error(error.message)
        })
    }
}

export const setKnowledgeBaseOptionsAction = (data) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_OPTIONS_ACTION,
    data: data,
})

export const getKnowledgeBaseConfig = () => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/config/get', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/config/get data = ", data);
            dispatch(setKnowledgeBaseOptionsAction(convertKnowledgeBaseConfigToOptions(data.data.llm_names)))
            return data
        })
        .catch(error => {
            console.log("/api/knowledge_base/config/get error = ", error);
            message.error(error.message)
        })
    }
}

export const updateKnowledgeBaseConfig = (llm_name, min_match_score) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/config/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "llm_name": llm_name,
                "min_match_score": min_match_score,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/config/update data = ", data);
            message.success(data.msg)
            return true
        })
        .catch(error => {
            console.log("/api/knowledge_base/config/update error = ", error);
            message.error(error.message)
        })
    }
}

export const setEditKnowledgeBaseName = (value) => ({
    type: actionTypes.SET_EDIT_KNOWLEDGE_BASE_NAME,
    value: value,
})

export const updateKnowledgeBase = (name, new_name, overview, description, is_active) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/knowledge_base/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "is_active": is_active,
                "new_name": new_name,
                'overview': overview,
                'description': description,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/knowledge_base/update data = ", data);
            dispatch(fetchKnowledgeBaseList())
            return true
        })
        .catch(error => {
            console.log("/api/knowledge_base/update error = ", error);
            message.error(error.message)
        })
    }
}

export const deleteReleaseRecord = (id) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/delete_release_record', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "id": id,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/delete_release_record data = ", data);
            message.success(data.msg)
            dispatch(fetchVersionUpdateList())
        })
        .catch(error => {
            console.log("/api/admin/delete_release_record error = ", error);
            message.error(error.message)
        })
    }
}

export const setFilterDates = (data) => ({
    type: actionTypes.SET_FILTER_DATES,
    data: data,
})


export const fetchAvgDailyActiveInfo = () => {
    return (dispatch, getState) => {
        let start_time = getStartOfMonth()
        let end_time = getStartOfToday()
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        fetchWithCookieRefresh('/api/admin/avg_daily_active_info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "start_time": start_time,
                "end_time": end_time,
                "timezone": timezone,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/avg_daily_active_info data = ", data);
            dispatch(setUserStatisticsMonthDayAvg(data.data.avg))
        })
        .catch(error => {
            console.log("/api/admin/avg_daily_active_info error = ", error);
        })
    }
}

export const setUserStatisticsMonthDayAvg = (value) => ({
    type: actionTypes.SET_USER_STATISTICS_MONTH_DAY_AVG,
    value: value,
})

export const setKnowledgeBaseListSearchName = (value) => ({
    type: actionTypes.SET_KNOWLEDGE_BASE_LIST_SEARCH_NAME,
    value: value,
})

export const fetchUserCategorize = (uuid, isVerified, category) => {
    let args = `?user=${uuid}&category=${category}&isVerified=${isVerified === 'yes' ? true : false}`
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/get' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/message/category/get data = ", data);
            let r = transformCategorizeData(data.data)
            return r
        })
        .catch(error => {
            console.log("/api/admin/message/category/get error = ", error);
        })
    }
}

export const fetchAllUserCategorize = (isVerified, category) => {
    let args = `?category=${category}&is_verified=${isVerified === 'yes' ? true : false}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/list' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/message/category/list data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/message/category/list error = ", error);
        })
    }
}

export const fetchUpdateUserCategorizeMessage = (select_item, number, isDelete) => {
    let args = `?category=${select_item}&question_count=${number}&delete_message_category=${isDelete}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/match' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }, 1, 60000000)
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/message/category/match");
            return data
        })
        .catch(error => {
            console.log("/api/admin/message/category/match error = ", error);
            throw error
        })
    }
}

export const fetchUserCategorizeDetail = (category_name, category, isVerified, active_uuid='') => {
    let args = ''
    if (active_uuid) {
        args = `?category_name=${category_name}&category=${category}&user=${active_uuid}&is_verified=${isVerified === 'yes' ? true : false}`
    } else {
        args = `?category_name=${category_name}&category=${category}&is_verified=${isVerified === 'yes' ? true : false}`
    }

    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/detail' + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/message/category/detail data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/message/category/detail error = ", error);
            throw error
        })
    }
}

export const fetchCategoryList = () => {
    return (dispatch, getState) => {
        // return fetch('/api/admin_category_list.json', {
        return fetchWithCookieRefresh('/api/admin/category/get', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/category/get data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/category/get error = ", error);
            throw error
        })
    }
}

export const createCategoryList = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/category/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/category/create data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/category/create error = ", error);
            throw error
        })
    }
}

export const updateCategoryList = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/category/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/category/update data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/category/update error = ", error);
            throw error
        })
    }
}

export const deleteCategoryList = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/category/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/category/delete data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/category/delete error = ", error);
            throw error
        })
    }
}

export const fetchCategoryMatchNumber = (category) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/message/category/match_number?category=` + category, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/message/category/match_number data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/message/category/match_number error = ", error);
            throw error
        })
    }
}

export const updateMessageCategory = (data) => {
    return (dispatch, getState) => {
        return fetchWithCookieRefresh('/api/admin/message/category/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/message/category/update data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/message/category/update error = ", error);
        })
    }
}

export const getMessageCategoryVerify = (category) => {
    let args = `?category=${category}`

    return (dispatch, getState) => {
        return fetchWithCookieRefresh(`/api/admin/message/category/verify` + args, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            } else {
                return response.json()
            }
        })
        .then(data => {
            console.log("/api/admin/message/category/verify` data = ", data);
            return data
        })
        .catch(error => {
            console.log("/api/admin/message/category/verify` error = ", error);
            throw error
        })
    }
}
