const createController = () => {
    return new AbortController();
};

// 封装 fetch 请求，自动处理 cookie 刷新逻辑
const fetchWithCookieRefresh = async (url, options, retryCount = 1, timeout = 60000) => {
    let timeoutId; // 用于存储超时定时器的 ID

    // 创建一个可以取消的 fetch 请求
    const fetchPromise = new Promise((resolve, reject) => {
        fetch(url, { ...options }).then(resolve).catch(reject)
    })

    // 创建用于超时的 Promise
    const timeoutPromise = new Promise((_, reject) => {
        timeoutId = setTimeout(() => {
            console.log("timeout request error")
            reject(new Error('Request timed out'))
        }, timeout)
    })

    try {
        // 使用 Promise.race 来同时处理超时和 fetch 请求
        const response = await Promise.race([
            fetchPromise,
            timeoutPromise
        ]);

        clearTimeout(timeoutId) // 清除超时定时器

        // 当响应状态码是 401 时，检查 body 中的值
        if (response.status === 401) {
            const responseBody = await response.json(); // 假设响应内容是 JSON 格式
            // 检查响应体是否有特定的 detail 值
            if (responseBody.detail === 'Signature has expired' && retryCount > 0) {
                await refreshCookie(); // 刷新 cookie
                return fetchWithCookieRefresh(url, options, retryCount - 1, timeout); // 重新发送请求
            } else {
                // 如果认证没通过，将删除相关登录信息，回到登录页
                // responseBody.detail === 'Signature verification failed'
                localStorage.removeItem("loginState")
                window.location.reload()
            }
        }

        // 返回原始响应对象
        return response
    } catch (error) {
        clearTimeout(timeoutId)
        console.error('Fetch with cookie refresh failed:', error)
        throw error
    }
}

// 处理 cookie 刷新逻辑的函数
const refreshCookie = async () => {
    try {
        const refreshResponse = await fetch('/api/account/refresh', {
            method: 'POST',
            // credentials: 'include' // 如果你的cookie是HTTP-only，确保包含这个选项
        })
        if (refreshResponse.ok) {
            console.log("已刷新");
        } else if (refreshResponse.status === 401) {
            localStorage.removeItem("loginState")
            window.location.reload()
        } else {
            throw new Error('Cookie refresh failed')
        }
    } catch (error) {
        console.error('Error refreshing cookie:', error)
        throw error
    }
}

export {
    fetchWithCookieRefresh,
    createController,
}