import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Divider, Tooltip, Popover } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import { toggleChatTypeActive, getTopActiveModelsByWeight } from '../../../../utils';

import homeImg from '../../../../statics/chat_type_home.png'
import expandImg from '../../../../statics/chat_type_expand.png'
import shrinkImg from '../../../../statics/chat_type_shrink.png'
import menuImg from '../../../../statics/menu.png'
import popverKeepChatTypeImg from '../../../../statics/popover_keep_chat_type.png'
import popverDeleteChatTypeImg from '../../../../statics/popover_delete_chat_type.png'


class SideBarChatType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false, // 默认不收缩
            maxHeight: null,
        }
        this.contentRef = React.createRef();
    }

    toggleCollapse() {
        const contentHeight = this.contentRef.current.scrollHeight; // 获取内容的实际高度
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed,
            maxHeight: !prevState.isCollapsed ? 0 : contentHeight + 'px', // 设置 maxHeight 为内容高度或 0
        }))
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.isCollapsed) {
            const contentHeight = this.contentRef.current.scrollHeight;
            if (prevState.maxHeight !== contentHeight + 'px') {
                this.setState({
                    maxHeight: contentHeight + 'px' // 更新 maxHeight 为当前内容的高度
                });
            }
        }
    }

    handleItemKeep(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleItemDelete(e, value) {
        e.preventDefault()
        e.stopPropagation()

        if (value !== 'link_stone') {
            let chat_type_options = this.props.chat_type_options.toJS()
            let result = toggleChatTypeActive(chat_type_options, value)

            if (result.isUpdated) {
                this.props.handleChatTypeOptionsAction(result.chat_type_options)
            }
        }
    }

    generatePopoverContent(item) {
        let content = (
            <div className={styles.popover_box}>
                <div
                    className={styles.popover_item}
                    onClick={this.handleItemKeep.bind(this)}
                >
                    <img src={popverKeepChatTypeImg} className={styles.popover_butn_img} />
                    <span className={styles.popover_item_text}>保持在侧边栏</span>
                </div>

                <div
                    className={styles.popover_item}
                    onClick={(e) => {this.handleItemDelete(e, item.value)}}
                >
                    <img src={popverDeleteChatTypeImg} className={styles.popover_butn_img} />
                    <span className={styles.popover_item_text}>从侧边栏移除</span>
                </div>
            </div>
        )

        return content
    }

    render() {
        let chat_type_options = this.props.chat_type_options.toJS()
        let temp_chat_type_options = getTopActiveModelsByWeight(chat_type_options, 4)

        return (
            <div
                className={styles.container}
                // className={`${styles.container} ${this.state.isCollapsed ? styles.container_shrink : ''}`}
            >
                <div className={styles.main}>
                    <div
                        className={styles.title_item}
                        id="tour1"
                    >
                        <div
                            className={styles.expert_butn_box}
                            onClick={this.handleExpertDetail.bind(this)}
                        >
                            <img src={homeImg} className={styles.img} />
                            <div className={styles.text}>专家助手</div>
                        </div>
                        <div className={styles.shrink_box} onClick={this.toggleCollapse.bind(this)}>
                            {
                                this.state.isCollapsed ? (
                                    <img src={shrinkImg} className={styles.shrink_img} />
                                ) : (
                                    <img src={expandImg} className={styles.shrink_img} />
                                )
                            }
                        </div>
                    </div>

                    <div
                        ref={this.contentRef}
                        className={`${styles.collapsibleContent} ${this.state.isCollapsed ? styles.item_collapsed : styles.item_expanded}`}
                        style={{maxHeight: this.state.maxHeight}}
                    >
                        {
                            temp_chat_type_options.map((item) => {
                                let icon = item.avatar_tb

                                if (item.active) {
                                    return (
                                        <div
                                            className={styles.item}
                                            onClick={(e) => {this.handleChatTypeChange(item.value, e)}}
                                            key={item.value}
                                        >
                                            <img src={icon} className={styles.butn_img} />
                                            <span className={styles.text}>{item.label}</span>
                                            {/* <div className={styles.shrink_box}>
                                                <img src={shrinkImg} className={styles.shrink_img} />
                                            </div> */}

                                            {
                                                item.value === 'link_stone' ? null : (
                                                    <Popover
                                                        overlayClassName='custom_popover_chattype'
                                                        content={this.generatePopoverContent(item)}
                                                        placement="right"
                                                        trigger="click"
                                                    >
                                                        <Tooltip title="更多" placement="top">
                                                            <img
                                                                src={menuImg}
                                                                alt={"菜单"}
                                                                className={styles.butns_more_img}
                                                                onClick={this.handleItemMenu.bind(this)}
                                                            />
                                                        </Tooltip>
                                                    </Popover>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    handleChatTypeChange(value, event) {
        this.props.handleChatTypeChangeAction(value)
    }

    handleExpertDetail() {
        this.props.handleIsShowExpertSelectAction(false)
        this.props.handleIsShowExpertDetailAction(true)
        this.props.handleExpertRelatedAction()
    }

    handleItemMenu(e) {
        e.preventDefault()
        e.stopPropagation(); // 阻止事件冒泡
    }
}

const mapStateToProps = (state) => {
    return {
        chat_type: state.get('home').get('chat_type'),
        chat_type_options: state.get('home').get('chat_type_options'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleChatTypeChangeAction(value) {
            dispatch(actionCreators.chatTypeChangeAction(value))
        },
        handleIsShowExpertSelectAction(status) {
            dispatch(actionCreators.isShowExpertSelectAction(status))
        },
        handleIsShowExpertDetailAction(status) {
            dispatch(actionCreators.isShowExpertDetailAction(status))
        },
        handleExpertRelatedAction() {
            dispatch(actionCreators.expertRelatedAction())
        },
        handleChatTypeOptionsAction(data) {
            dispatch(actionCreators.chatTypeOptionsAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarChatType)