import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Tour } from 'antd';

import styles from './index.module.css'
import { actionCreators } from '../../store'

function CustomTourTitle({ title }) {
    return (
        <div className={styles.item_title}>
            { title }
        </div>
    )
}

function CustomTourDescription({ description }) {
    return (
        <div className={styles.item_description}>
            { description }
        </div>
    )
}

function CustomTourIndicators({ current, total, updateCurrent }) {
    // 调用传入的 updateCurrent 函数来更新 custom_current
    const handleChange = (newVal) => {
        updateCurrent(newVal)
    };

    const arr = new Array(total).fill(null); // 使用 null 填充数组，也可以用其他值替换

    return (
        <div className={styles.item_indicators}>
            {
                arr.map((item, index) => {
                    if (current === index) {
                        return (
                            <div
                                key={index}
                                className={`${styles.item_indicators_circle} ${styles.item_indicators_circle_active}`}
                            >
                            </div>
                        )
                    }

                    return (
                        <div
                            key={index}
                            className={styles.item_indicators_circle}
                            onClick={() => {handleChange(index)}}
                        >
                        </div>
                    )
                })
            }
        </div>
    )
}

class BeginnerGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custom_current: 0,
        }
    }

    generateSteps() {
        let r = [
            { id: "tour1", title: '专家助手', placement: 'right', description: '你永远可以随时切换你想要的专家助手来为你服务，不同类型的设计问题由不同的专家来解答效果更好!', },
            { id: "tour2", title: '搜索栏', placement: 'bottom', description: '在这里可以随时搜索到曾经今你难忘的历史对话！', },
            { id: "tour3", title: '反馈', placement: 'bottom', description: '这是反馈-灵石不是完美的，是通过你的建议它会越来懂你！', },
            { id: "tour4", title: '收藏夹', placement: 'bottom', description: '这是收藏夹-在这里可以找到你收藏的历史对话。', },
            { id: "tour5", title: '设置', placement: 'bottom', description: '这是设置-在这里你可以查看您的个人资料。', },
            { id: "tour6", title: '开始对话', placement: 'right', description: '点击这里直接开启对话，我们会为你推荐与你问题最匹配的专家模型！', },
        ]

        let result = r.map((item, index) => {
            let next_children
            let children_text = ''
            if (r.length !== index + 1) {
                children_text = '继续'
            } else {
                children_text = '完成'
            }

            next_children = (
                <div className={styles.next_butn}>
                    <span className={styles.next_butn_text}>{children_text}</span>
                    <div className={styles.next_butn_icon}></div>
                </div>
            )

            return {
                className: `custom_tour_${item.id}`,
                title: <CustomTourTitle title={item.title}></CustomTourTitle>,
                description: <CustomTourDescription description={item.description}></CustomTourDescription>,
                target: () => {
                    return document.querySelector(`#${item.id}`)
                },
                placement: item.placement,
                arrow: false,
                prevButtonProps: {
                    // children: <div>上一步</div>,
                    style: {display: 'none'}
                },
                nextButtonProps: {
                    children: next_children,
                    // onClick?: () => void;
                    className: r.length !== index + 1 ? 'custom_next_butn_box' : 'custom_next_butn_done_box',
                    style: {width: '100%'},
                },
            }
        })

        return result
    }

    render() {
        let steps = this.generateSteps()

        return (
            <Tour
                open={this.props.is_show_guide}
                onClose={this.onTourClose.bind(this)}
                onChange={this.onTourChange.bind(this)}
                steps={steps}
                current={this.state.custom_current}
                rootClassName={"custom_tour"}
                disabledInteraction={true}
                indicatorsRender={(current, total) => {
                    return (
                        <CustomTourIndicators
                            current={current}
                            total={total}
                            updateCurrent={this.updateCurrent.bind(this)}
                        >
                        </CustomTourIndicators>
                    )
                }}
            />
        )
    }

    onTourClose() {
        this.props.handleIsShowGuideAction(false)
        let updateInfo = this.props.updateInfo.toJS()
        if (updateInfo.has_update) {
            // 在关闭了新手指南之后，将会开启当前的更新内容信息
            this.props.handleIsUpdateInfoModalOpenAction(true)
        }
    }

    onTourChange(e) {
        console.log("onTourChange e = ", e);
        this.updateCurrent(e)
    }

    updateCurrent(newVal) {
        this.setState({ custom_current: newVal });
    }
}

const mapStateToProps = (state) => {
    return {
        is_show_guide: state.get('home').get('is_show_guide'),
        updateInfo: state.get('home').get('updateInfo'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowGuideAction(status) {
            dispatch(actionCreators.isShowGuideAction(status))
        },
        handleIsUpdateInfoModalOpenAction(status) {
            dispatch(actionCreators.isUpdateInfoModalOpenAction(status))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeginnerGuide)