import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Dropdown, Popover, message, Tooltip } from 'antd'

import { actionCreators } from '../../store'
import styles from './index.module.css'
import { toggleChatTypeActive } from '../../../../utils';

import sideSuspendButnImg from '../../../../statics/side_suspend_butn.png'
import dropdownNavImg from '../../../../statics/header_dropdown_nav.png'
import popverKeepChatTypeImg from '../../../../statics/popover_keep_chat_type.png'
import popverDeleteChatTypeImg from '../../../../statics/popover_delete_chat_type.png'
import popverEditChatTypeImg from '../../../../statics/popover_edit_chat_type.png'
import popverInfoChatTypeImg from '../../../../statics/popover_info_chat_type.png'


function CustomPopoverItemList(props) {
    const itemCreate = () => {
        props.hidePopover()
        props.handleItemCreate(props.show_value)
    }

    const itemDelete = () => {
        props.hidePopover()
        props.handleItemDelete(props.show_value)
    }

    const itemInfo = () => {
        props.hidePopover()
    }

    const itemKeep = () => {
        props.hidePopover()
        props.handleItemKeep(props.show_value)
    }

    return (
        <div className={styles.popover_box}>
            <div
                className={styles.popover_item}
                onClick={() => { itemCreate() }}
            >
                <img src={popverEditChatTypeImg} className={styles.popover_butn_img} />
                <span className={styles.popover_item_text}>创建新对话</span>
            </div>

            {
                props.show_value === 'link_stone' ? null : (
                    props.is_active ? (
                        <div
                            className={styles.popover_item}
                            onClick={() => { itemDelete() }}
                        >
                            <img src={popverDeleteChatTypeImg} className={styles.popover_butn_img} />
                            <span className={styles.popover_item_text}>从侧边栏移除</span>
                        </div>
                    ) : (
                        <div
                            className={styles.popover_item}
                            onClick={() => { itemKeep() }}
                        >
                            <img src={popverKeepChatTypeImg} className={styles.popover_butn_img} />
                            <span className={styles.popover_item_text}>保持在侧边栏</span>
                        </div>
                    )
                )
            }

            <div
                className={styles.popover_item}
                onClick={() => { itemInfo() }}
            >
                <img src={popverInfoChatTypeImg} className={styles.popover_butn_img} />
                <span className={styles.popover_item_text}>了解该专家</span>
            </div>
        </div>
    )
}

function CustomPopover(props) {
    let [open, setOpen] = useState(false)

    const hidePopover = () => {
        setOpen(false)
    }

    return (
        <Popover
            content={() => {
                return (
                    <CustomPopoverItemList
                        show_value={props.show_value}
                        handleItemDelete={props.handleItemDelete}
                        hidePopover={hidePopover}
                        handleItemCreate={props.handleItemCreate}
                        is_active={props.is_active}
                        handleItemKeep={props.handleItemKeep}
                    />
                )
            }}
            placement="bottomLeft"
            open={open}
            trigger="click"
            onOpenChange={() => { setOpen(!open) }}
            arrow={false}
            overlayClassName={"custom_popover_header"}
        >
            <div className={styles.dropdown}>
                <div className={styles.dropdown_text}>{ props.show_title }</div>
                <img src={dropdownNavImg} className={styles.dropdown_img} />
            </div>
        </Popover>
    )
}


class ChatMessageHeaderDropdown extends Component {
    handleItemDelete(value) {
        if (value !== 'link_stone') {
            let chat_type_options = this.props.chat_type_options.toJS()
            let result = toggleChatTypeActive(chat_type_options, value)

            if (result.isUpdated) {
                this.props.handleChatTypeOptionsAction(result.chat_type_options)
            }
        }
    }

    handleChatTypeChange(value) {
        this.props.handleChatTypeChangeAction(value)
    }

    handleAddExpertModel(value) {
        if (value !== 'link_stone') {
            let chat_type_options = this.props.chat_type_options.toJS()
            let result = toggleChatTypeActive(chat_type_options, value)

            if (!result.isUpdated) {
                message.info("无法更新状态，因为固定到侧边栏的专家模型已经达到上限。")
            } else {
                this.props.handleChatTypeOptionsAction(result.chat_type_options)
            }
        }
    }

    generateMenuItems(chat_type_options) {
        let items = chat_type_options.map((option, index) => {
            return {
                label: <div>{option.label}</div>,
                key: option.value,
            }
        })

        return items
    }

    render() {
        let chat_type_options = this.props.chat_type_options.toJS()
        chat_type_options.push({ value: 'link_stone', label: '灵石', category: '', info: '', })
        let chat_type_obj = {}
        if (this.props.chat_type !== '') {
            chat_type_obj = chat_type_options.find(item => item.value === this.props.chat_type) || {}
        }
        let chat_type_name = chat_type_obj?.label ? chat_type_obj.label : ''
        let items = this.generateMenuItems(chat_type_options)

        return (
            <div className={styles.container}>
                {
                    this.props.is_zoom ? (
                        <Tooltip placement="bottom" title={"新聊天"}>
                            <img
                                src={sideSuspendButnImg}
                                className={styles.side_suspend_img}
                                onClick={this.showExpertSelect.bind(this)}
                            />
                        </Tooltip>
                    ) : ''
                }

                {/* <Dropdown
                    menu={{
                        items: items,
                        onClick: this.handleChatTypeChange.bind(this),
                    }}
                    placement={'bottomLeft'}
                    trigger={['click']}
                >
                    <div className={styles.dropdown}>
                        <div className={styles.dropdown_text}>{chat_type_name}</div>
                        <img src={dropdownNavImg} className={styles.dropdown_img} />
                    </div>
                </Dropdown> */}
                <CustomPopover
                    show_value={chat_type_obj.value}
                    show_title={chat_type_obj.label}
                    is_active={chat_type_obj.active}
                    handleItemDelete={this.handleItemDelete.bind(this)}
                    handleItemCreate={this.handleChatTypeChange.bind(this)}
                    handleItemKeep={this.handleAddExpertModel.bind(this)}
                >

                </CustomPopover>
            </div>
        )
    }

    showExpertSelect() {
        this.props.handleIsShowExpertSelectAction(true)
        this.props.handleIsShowExpertDetailAction(false)
        this.props.handleExpertRelatedAction()
    }
}

const mapStateToProps = (state) => {
    return {
        is_zoom: state.get('home').get('is_zoom'),
        chat_type: state.get('home').get('chat_type'),
        chat_type_options: state.get('home').get('chat_type_options'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowExpertSelectAction(status) {
            dispatch(actionCreators.isShowExpertSelectAction(status))
        },
        handleIsShowExpertDetailAction(status) {
            dispatch(actionCreators.isShowExpertDetailAction(status))
        },
        handleExpertRelatedAction() {
            dispatch(actionCreators.expertRelatedAction())
        },
        handleChatTypeChangeAction(value) {
            dispatch(actionCreators.chatTypeChangeAction(value))
        },
        handleChatTypeOptionsAction(data) {
            dispatch(actionCreators.chatTypeOptionsAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessageHeaderDropdown)