import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'

import { actionCreators } from './store'
import styles from './index.module.css'

import ChatMessage from './components/ChatMessage'
import ChatMessageHeader from './components/ChatMessageHeader';
import SideBar from './components/SideBar'
import BeginnerGuide from './components/BeginnerGuide'
import ModalFeedback from '../../common/ModalFeedback'
import ModalFeedbackDone from '../../common/ModalFeedbackDone'
import ModalUserInfo from '../../common/ModalUserInfo'
import SideBarArrow from './components/SideBarArrow'
import ModalImageView from './components/ModalImageView'
import ChatTypeSelectionScreen from './components/ChatTypeSelectionScreen'
import ChatTypeDetailScreen from './components/ChatTypeDetailScreen'
import UserResearch from './components/UserResearch'
import ImageMover from './components/ImageMover'
import ModalBlockUsage from './components/ModalBlockUsage'
import ModalOverallUpdateInfo from './components/ModalOverallUpdateInfo'
import ChatMessageFooter from './components/ChatMessageFooter'
import ModalExperienceUpdateInfo from './components/ModalExperienceUpdateInfo'

import introVideo from '../../statics/intro.mp4'; // 导入视频


class Home extends Component {
    componentDidMount() {
        if (this.props.has_login) {
            console.log("获取所有历史记录");
            this.props.handleInitAllConversationHistory()
            // this.props.handleInitAllChatHistory()
            // 获取 release 的版本更新信息
            this.props.handleGetReleaseContent()
            // 获取模型信息
            this.props.handleInitModelInfo()
            // 获取模型分类信息
            this.props.handleGetKnowledgeBaseCategorys()
            // 清除 url 上多余的参数
            this.clearExcessParameters()
            // // 获取自定义的知识库模型信息
            // this.props.handleInitKnowledgeBaseInfo()
            // 获取经验值等级信息
            this.props.handleGetLevelInfo()
            // 获取用户的当前经验值
            this.props.handleGetLevelChatCount()
        }
    }

    onVideoEnd() {
        this.props.handleIsShowIntroVideoAction(false)
        this.props.handleIsShowImageMoverAction(true)
    }

    isEmptyModel() {
        if (
            !this.props.is_show_expert_select &&
            !this.props.is_show_expert_detail &&
            this.props.active_date === '' &&
            this.props.active_topicid === ''
        ) {
            return true
        } else {
            return false
        }
    }

    clearExcessParameters() {
        // console.log("============before===========");
        // console.log("this.props = ", this.props);
        // console.log("============before===========");

        // 使用 window.location 对象获取URL的各个部分
        let { protocol, host, pathname } = window.location;
        pathname = '/#/home'

        // 构建新的不包含查询字符串的URL
        const newUrl = `${protocol}//${host}${pathname}`;

        // 使用 history.replaceState 更新页面状态和 URL（不会添加新的历史记录）
        window.history.replaceState({}, document.title, newUrl);
    }

    render() {
        if (!this.props.has_login) {
            return <Redirect to="/" />
        }

        if (this.props.is_show_user_research) {
            return (
                <UserResearch />
            )
        }

        if (this.props.is_show_intro_video) {
            return (
                <div className={styles.video_box}>
                    <video
                        src={introVideo}
                        width="100%"
                        height="100%"
                        autoPlay={true}
                        muted={true} // 静音播放
                        preload="auto"
                        onEnded={this.onVideoEnd.bind(this)}
                    ></video>
              </div>
            )
        }

        if (this.props.is_show_image_mover) {
            return (
                <ImageMover />
            )
        }

        // 专家切换的时候，新的界面改成渐出
        let is_empty_model = this.isEmptyModel()
        let keyHeaderProps = is_empty_model ? {key: this.props.chat_type + `header`} : {}
        let keyContentProps = is_empty_model ? {key: this.props.chat_type + `content`} : {}

        let main_unit = ''
        if (this.props.is_show_expert_select) {
            main_unit = <ChatTypeSelectionScreen />
        } else if (this.props.is_show_expert_detail) {
            main_unit = <ChatTypeDetailScreen />
        } else {
            main_unit = <ChatMessage />
        }

        return (
            <div className={styles.container}>
                <div className={`${styles.sidebar} ${this.props.is_zoom ? styles.hide_sidebar : ''}`}>
                    <SideBar />
                </div>

                <div className={`${styles.content} ${this.props.is_zoom ? styles.content_zoom : ''}`}>
                    <div className={`${styles.header} ${is_empty_model ? styles.ease_in_out : ''}`} {...keyHeaderProps}>
                        <ChatMessageHeader />
                    </div>

                    <div className={`${styles.main} ${is_empty_model ? styles.ease_in_out : ''}`} {...keyContentProps}>
                        {
                            main_unit
                        }
                    </div>

                    <div className={styles.side_arrow}>
                        <SideBarArrow />
                    </div>

                    <div className={styles.footer}>
                        <ChatMessageFooter />
                    </div>
                </div>

                <ModalFeedback />
                <ModalFeedbackDone />
                <ModalUserInfo />
                <BeginnerGuide />
                <ModalImageView />
                <ModalBlockUsage />
                <ModalOverallUpdateInfo />
                <ModalExperienceUpdateInfo />
                {/* <ModalOverallUpdateInfo /> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        has_login: state.get('login').get('has_login'),
        is_zoom: state.get('home').get('is_zoom'),
        is_show_intro_video: state.get('home').get('is_show_intro_video'),
        is_show_expert_select: state.get('home').get('is_show_expert_select'),
        is_show_expert_detail: state.get('home').get('is_show_expert_detail'),
        is_show_user_research: state.get('home').get('is_show_user_research'),
        is_show_image_mover: state.get('home').get('is_show_image_mover'),
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
        chat_type: state.get('home').get('chat_type'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowIntroVideoAction(status) {
            dispatch(actionCreators.isShowIntroVideoAction(status))
        },
        handleInitAllChatHistory() {
            dispatch(actionCreators.initAllChatHistory())
        },
        handleInitAllConversationHistory() {
            dispatch(actionCreators.initAllConversationHistory())
        },
        handleIsShowImageMoverAction(status) {
            dispatch(actionCreators.isShowImageMoverAction(status))
        },
        handleInitModelInfo() {
            dispatch(actionCreators.initModelInfo())
        },
        handleInitKnowledgeBaseInfo() {
            dispatch(actionCreators.initKnowledgeBaseInfo())
        },
        handleGetReleaseContent() {
            dispatch(actionCreators.getReleaseContent())
        },
        handleGetKnowledgeBaseCategorys() {
            dispatch(actionCreators.getKnowledgeBaseCategorys())
        },
        handleGetLevelInfo() {
            dispatch(actionCreators.getLevelInfo())
        },
        handleGetLevelChatCount() {
            dispatch(actionCreators.getLevelChatCount())
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))