import React, { useState, useEffect } from 'react';
import { Card, Statistic, Button } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'

import { actionCreators } from '../../store'
import styles from './index.module.css'

const generateItems = (data) => {
    let count = 0
    for (let item of data) {
        let c = item.query_count + item.re_query_count
        count += c
    }

    let items = [
        {
            key: 'total_conversation',
            title: '总对话次数',
            value: count,
            // precision: 2,
            suffix: '次',
            style: { color: '#157efb' },
        },
    ]

    return items
}

const ConversationCountUsageCard = () => {
    const dispatch = useDispatch()
    let conversation_count_data = useSelector(state => state.get('admin').get('conversation_count_data'))
    const [items, setItems] = useState([])
    useEffect(() => {
        console.log("副作用函数的执行");
        setItems(generateItems(conversation_count_data.toJS()))
    }, [conversation_count_data])

    return (
        <div className={styles.main}>
            {
                items.map((item) => {
                    return (
                        <Card className={styles.card} key={item.key}>
                            <Statistic
                                title={item.title}
                                value={item.value}
                                precision={item.precision}
                                suffix={item.suffix}
                                valueStyle={item.style}
                            />
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default ConversationCountUsageCard