import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip, Select } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import ChatMessageHeaderDropdown from '../ChatMessageHeaderDropdown'
import { filterSearchInfo } from '../../../../utils'

import menuSearchIcon from '../../../../statics/menu_search_icon.png'
import searchInfoItemIcon from '../../../../statics/search_info_item_icon.png'


class ChatMessageHeader extends Component {
    constructor(props) {
        super(props)
        // 初始化 state
        this.state = {
            isFocused: false,
            isMouseEnter: false,
        }
    }

    switchItem(active_date, active_topicid) {
        if (this.props.active_date !== active_date || this.props.active_topicid !== active_topicid) {
            this.props.handleSwitchItemAction(active_date, active_topicid)
        }
        this.setState({ isFocused: false, isMouseEnter: false, })
    }

    render() {
        let all_chat_data = this.props.all_chat_data.toJS()
        let menu_search_content = this.props.menu_search_content.trim()
        let list = filterSearchInfo(all_chat_data)
        let search_list = list.filter(item =>
            menu_search_content && item.conversation_name.includes(menu_search_content)
        );

        return (
            <div className={styles.container}>
                <div className={styles.left}>
                    {
                        this.props.is_show_expert_select || this.props.is_show_expert_detail ? null : (
                            <ChatMessageHeaderDropdown />
                        )
                    }
                </div>

                <div className={`${styles.right} ${this.props.is_show_block_usage ? styles.fade_in : ''}`}>
                    <div
                        className={styles.search_box}
                    >
                        <input
                            type={"text"}
                            placeholder={'搜索'}
                            className={styles.search}
                            onChange={this.onSearchChange.bind(this)}
                            value={this.props.menu_search_content}
                            onFocus={() => { this.setState({ isFocused: true }) }}
                            onBlur={() => { this.setState({ isFocused: false }) }}
                        />
                        <img src={menuSearchIcon} className={styles.search_icon} />
                        <div
                            className={styles.search_box_mask}
                            id="tour2"
                        >
                        </div>
                        {
                            this.state.isFocused || this.state.isMouseEnter ? (
                                <div
                                    className={styles.search_info}
                                    onMouseEnter={() => { this.setState({ isMouseEnter: true })}}
                                    onMouseLeave={() => { this.setState({ isMouseEnter: false })}}
                                >
                                    <div className={styles.search_info_header}>历史记录</div>
                                    {
                                        search_list.map((item) => {
                                            return (
                                                <div className={styles.search_info_item} key={item.id}>
                                                    <div
                                                        className={styles.search_info_item_box}
                                                        onClick={() => { this.switchItem(item.date, item.topic_id) }}
                                                    >
                                                        <img src={searchInfoItemIcon} className={styles.search_info_item_icon} />
                                                        <div className={styles.search_info_item_name}>
                                                            { item.conversation_name }
                                                        </div>
                                                    </div>
                                                    <div className={styles.search_info_item_line}></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }
                    </div>

                    <Tooltip placement="bottom" title={"反馈"}>
                        <div
                            className={styles.img_box}
                            onClick={this.handleFeedbackModal.bind(this)}
                        >
                            <div
                                className={`${styles.img} ${styles.feedback_img}`}
                                data-alt="反馈"
                            >
                            </div>
                            <div
                                className={styles.search_box_mask}
                                id="tour3"
                            >
                            </div>
                        </div>
                    </Tooltip>

                    <Tooltip placement="bottom" title={"收藏"}>
                        <div
                            className={styles.img_box}
                            onClick={this.handleGoToCollection.bind(this)}
                        >
                            <div className={`${styles.img} ${styles.collect_img}`} data-alt="收藏"></div>
                            <div
                                className={styles.search_box_mask}
                                id="tour4"
                            >
                            </div>
                        </div>
                    </Tooltip>

                    <Tooltip placement="bottom" title={"设置"}>
                        <div
                            className={styles.img_box}
                            onClick={this.handleUserInfoSetting.bind(this)}
                        >
                            <div className={`${styles.img} ${styles.setting_img}`} data-alt="设置"></div>
                            <div
                                className={styles.search_box_mask}
                                id="tour5"
                            >
                            </div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    }

    onSearchChange(e) {
        let value = e.target.value
        this.props.handleMenuSearchContentAction(value)
    }

    handleFeedbackModal() {
        this.props.handleIsFeedbackModalOpenAction(true)
    }

    handleGoToCollection() {
        this.props.history.push({ pathname: "/collect" })

        // 使用 HashRouter 时，URL 应该包括 '#'
        // const route = '/collect';
        // const url = window.location.origin + '/#' + route;
        // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        // if (newWindow) newWindow.opener = null;
    }

    handleUserInfoSetting() {
        this.props.handleUserinfoModalOpenAction()
    }
}

const mapStateToProps = (state) => {
    return {
        menu_search_content: state.get('home').get('menu_search_content'),
        is_show_expert_select: state.get('home').get('is_show_expert_select'),
        is_show_expert_detail: state.get('home').get('is_show_expert_detail'),
        is_show_block_usage: state.get('home').get('is_show_block_usage'),
        all_chat_data: state.get('home').get('all_chat_data'),
        active_date: state.get('home').get('active_date'),
        active_topicid: state.get('home').get('active_topicid'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsThinkingAction(status) {
            dispatch(actionCreators.isThinkingAction(status))
        },
        handleIsFeedbackModalOpenAction(status) {
            dispatch(actionCreators.isFeedbackModalOpenAction(status))
        },
        handleMenuSearchContentAction(value) {
            dispatch(actionCreators.menuSearchContentAction(value))
        },
        handleUserinfoModalOpenAction() {
            dispatch(actionCreators.handleUserinfoModalOpenAction())
        },
        handleSwitchItemAction(active_date, active_topicid) {
            dispatch(actionCreators.switchItemAction(active_date, active_topicid))
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatMessageHeader))