import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Layout, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN'

import styles from './index.module.css'
import { actionCreators } from './store'
import { actionCreators as homeActionCreators } from '../home/store'
import SideMenu from './components/SideMenu'
import UserStatistics from './components/UserStatistics'
import ConversationCountStatistics from './components/ConversationCountStatistics'
import PersonalConversation from './components/PersonalConversation'
import KnowledgeBase from './components/KnowledgeBase'
import VersionUpdateInfo from './components/VersionUpdateInfo'
import UserPermissionManagement from './components/UserPermissionManagement'
import GroupPermissionManagement from './components/GroupPermissionManagement'
import ModelManagement from './components/ModelManagement'
import CategorizeUserManagement from './components/CategorizeUserManagement'
import CategorizeAllManagement from './components/CategorizeAllManagement'
import CategorizeGenerateManagement from './components/CategorizeGenerateManagement'
import CategorizeSettingManagement from './components/CategorizeSettingManagement'


class Admin extends Component {
    componentDidMount() {
        let chat_type_options = this.props.chat_type_options.toJS()
        if (chat_type_options.length === 0) {
            // 获取模型信息
            this.props.handleInitModelInfo()
        }
    }

    render() {
        if (!this.props.has_login) {
            return <Redirect to="/" />
        }

        let user_data = this.props.user_data.toJS()
        if (!user_data.admin) {
            return <Redirect to="/home" />
        }

        let active_navbar = this.props.active_navbar
        let content = ''
        if (active_navbar === 'user_info') {
            content = <UserStatistics />
        } else if (active_navbar === 'user_conversation_count') {
            content = <ConversationCountStatistics />
        } else if (active_navbar === 'personal_conversation') {
            content = <PersonalConversation />
        } else if (active_navbar === 'data_manage') {
            content = <KnowledgeBase />
        } else if (active_navbar === 'model_manage') {
            content = <ModelManagement />
        } else if (active_navbar === 'version_update') {
            content = <VersionUpdateInfo />
        } else if (active_navbar === 'user_permission_management') {
            content = <UserPermissionManagement />
        } else if (active_navbar === 'group_permission_management') {
            content = <GroupPermissionManagement />
        } else if (active_navbar === 'categorize_user_management') {
            content = <CategorizeUserManagement />
        } else if (active_navbar === 'categorize_all_management') {
            content = <CategorizeAllManagement />
        } else if (active_navbar === 'categorize_generate_management') {
            content = <CategorizeGenerateManagement />
        } else {
            // categorize_setting_management
            content = <CategorizeSettingManagement />
        }

        return (
            <ConfigProvider locale={zhCN}>
                <Layout className={styles.main}>
                    <SideMenu />
                    <Layout>
                        <Layout.Content className={styles.content}>
                            {
                                content
                            }
                        </Layout.Content>
                    </Layout>
                </Layout>
            </ConfigProvider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        active_navbar: state.get('admin').get('active_navbar'),
        has_login: state.get('login').get('has_login'),
        chat_type_options: state.get('home').get('chat_type_options'),
        user_data: state.get('login').get('user_data'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // handleFetchUserStatisticsInfo() {
        //     dispatch(actionCreators.fetchUserStatisticsInfo())
        // },
        handleInitModelInfo() {
            dispatch(homeActionCreators.initModelInfo())
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin))