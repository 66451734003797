var scrollToBottom = (scrollElement) => {
    if (scrollElement) {
        scrollElement.scrollTop = scrollElement.scrollHeight
    }
}

var isDivScrolledToBottom = (element) => {
    // 滚动内容的整体高度 - 元素内部的可视区域的高度
    const maxScrollTop = element.scrollHeight - element.clientHeight;
    return element.scrollTop >= maxScrollTop - 1; // 减去1来处理浮点数问题和滚动精度问题
}

export {
    scrollToBottom,
    isDivScrolledToBottom,
}