import React, { useState, useEffect } from 'react';
import { Modal } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'

import { actionCreators } from '../../store'
import styles from './index.module.css'
import contentIcon from '../../../../statics/experience_show_icon.png'
import SideExperienceProgressBar from '../SideExperienceProgressBar'
import useUserLevel from '../../../../utils/useUserLevel'
import { checkExperience, experienceIcons } from '../../../../utils'

function ModalExperienceUpdateInfo() {
    const dispatch = useDispatch()
    const isModalExperienceOpen = useSelector(state => state.get('home').get('isModalExperienceOpen'))
    const current_experience = useSelector(state => state.get('home').get('current_experience'))
    const levelThresholds = useSelector(state => state.get('home').get("levelThresholds"))
    const { level, totalExperience } = useUserLevel(current_experience);
    // 弹窗状态
    const [modalInfo, setModalInfo] = useState('');
    let lv_n_icon = experienceIcons[level]

    useEffect(() => {
        let levels = levelThresholds.toJS()
        const result = checkExperience(levels, current_experience);
        if (result.showModal) {
            // 更新弹窗信息并显示
            dispatch(actionCreators.setIsModalExperienceOpen(true))
            setModalInfo(result.status)
            // 升级
            // 还差 2/3 就达到
            // 还差 1/3 就达到
        } else {
            setModalInfo('')
        }
    }, [current_experience, levelThresholds]);

    const handleDone = () => {
        dispatch(actionCreators.setIsModalExperienceOpen(false))
    }

    return (
        <Modal
            open={isModalExperienceOpen}
            onOk={handleDone}
            onCancel={handleDone}
            footer={null}
            width={590}
            height={606}
            className={styles.modal_container}
        >
            <div className={styles.main}>
                <div className={styles.header}>
                    <span className={styles.title}>继续加油!</span>
                </div>
                <div className={styles.content}>
                    <img src={contentIcon} className={styles.icon} alt={"icon"} />
                    <div className={styles.progress}>
                        <SideExperienceProgressBar area={'modal'} />
                    </div>
                    <div className={styles.info_box}>
                        <p className={styles.info}> +1 对话次数</p>
                        <p className={styles.info}>
                            {
                                modalInfo === '升级' || modalInfo === '' ? `你还差 ${ (totalExperience + 1) - current_experience } 次对话达到` : (
                                    `${modalInfo}`
                                )
                            }

                        </p>
                    </div>
                    <div className={styles.level_box}>
                        <img src={lv_n_icon} className={styles.lv_n_icon} alt="icon" />
                        <div className={styles.level}>Lv{level + 1}</div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div
                        className={styles.butn}
                        onClick={handleDone}
                    >
                        继续
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalExperienceUpdateInfo