import { fromJS } from 'immutable'

import * as actionTypes from './actionTypes'

const defaultState = fromJS({
    // 聊天记录相关
    all_chat_data: {
        today: [
            // {
            //     id: '',
            //     conversation_id: '',
            //     conversation_name: '新的对话',
            //     conversation_name_edit: '',
            //     created_at: "2024-06-07 04:00:11",
            //     is_edit_name: false,
            //     favorites_type: '1',
            //     topic_id: '113',
            //     has_get_images: false,
            //     chat_type: 'llm_chat',
            //     active: false,
            //     data: [
            //         {
            //             id: '',
            //             message_id: '123',
            //             topic_message_id: '123',
            //             updated_at: '2024-03-14 14:49:31',
            //             text: '你是谁？',
            //             text_extra_img: '',
            //             text_extra_search: '',
            //             image_ids: [],
            //             recommended_tools: [],
            //             search_result: [],
            //             image_urls: [],
            //             suggestion: [],
            //             status: 'pass',
            //             role: 'user',
            //             message_score: -1,
            //         },
            //         {
            //             id: '',
            //             message_id: '122',
            //             topic_message_id: '122',
            //             updated_at: '2024-03-14 14:49:31',
            //             text: '我是AI',
            //             text_extra_img: '',
            //             text_extra_search: '',
            //             image_ids: [
            //                 {
            //                     "index": 887,
            //                     "url": "",
            //                     "id": 1,
            //                     "score": 1,
            //                 },
            //             ],
            //             recommended_tools: ['a', 'b'],
            //             search_result: [
            //                 {
            //                     begin_page: 0,
            //                     doc_key: "",
            //                     doc_type: "",
            //                     fullText: "",
            //                     height: 0,
            //                     image: "",
            //                     length: 0,
            //                     payload: "",
            //                     pictures: [{}],
            //                     publishDate: "",
            //                     seeMoreUrl: "",
            //                     showName: "",
            //                     snippet: "",
            //                     summary: "",
            //                     title: "",
            //                     width: 0,
            //                 }
            //             ],
            //             image_urls: [],
            //             suggestion: [{
            //                 author: "bot",
            //                 createdAt: "",
            //                 messageType: "Suggestion",
            //                 text: "你能聊天以外的事情吗",
            //                 timestamp: "",
            //             },],
            //             status: 'pass',
            //             role: 'ai',
            //             message_score: -1,
            //         },
            //         {
            //             id: '',
            //             message_id: '1221',
            //             topic_message_id: '1221',
            //             updated_at: '2024-03-14 14:49:31',
            //             text: '',
            //             text_extra_img: '',
            //             text_extra_search: '',
            //             image_ids: [],
            //             recommended_tools: [],
            //             search_result: [],
            //             image_urls: [],
            //             suggestion: [],
            //             status: 'loading',
            //             role: 'ai',
            //             message_score: -1,
            //         },
            //     ]
            // },
        ],
        third_day: [],
        seventh_day: [],
        thirtieth_day: [],
    },
    chat_date_list: [
        { value: 'today', label: '今天', days_start: 0, days_end: 0, },
        { value: 'third_day', label: '过去3天', days_start: 1, days_end: 3, },
        { value: 'seventh_day', label: '过去7天', days_start: 4, days_end: 7, },
        { value: 'thirtieth_day', label: '过去30天', days_start: 8, days_end: 30 },
    ],
    active_date: '',     // today third_day seventh_day thirtieth_day
    active_topicid: '',
    thinking_topic_messageid: '',    // 正在问答的 message 的 id

    prompt: '',
    is_thinking: false,
    is_stop_fetch_responding: false,
    chat_type: '',
    chat_type_options: [
        // {
        //     id: 1,
        //     value: 'link_stone',
        //     label: '灵石 1.0',
        //     r_type: 'link_stone',
        //     active: true,
        //     weight: 11.9,
        //     category: 'line_stone_model',
        //     info: '最智能的模型，灵石可以自动为您匹配最合适的专家模型为您回答，无需切换模型。',
        //     permissions: ['read', 'write', 'execute', 'delete'],
        //     status: 'create',
        //     v_id: 1,
        //     avatar: '',
        //     avatar_tb: '',
        // },
    ],
    chat_type_show_list: {
        'tiangong_search': [
            {'query': '上海有什么民俗传统文化?', 'summarize': '地区传统介绍'},
            {'query': '广州天河区的未来规划?', 'summarize': '城市规划发展'},
            {'query': '华润的企业文化是什么?', 'summarize': '企业信息查询'},
        ],
        'llm_chat': [
            {'query': '请帮我把接下来提供的信息，整理成表格', 'summarize': '整理表格'},
            {'query': '帮我写一段回复甲方的信息，语气诚恳专业', 'summarize': '写回复'},
            {'query': '我的设计概念是生命之树，如何将此概念运用到大堂设计中!', 'summarize': '设计建议'},
        ],
        'bailian_chat': [
            {'query': '防火疏散通道宽度要求?', 'summarize': '建筑设计防火规范'},
            {'query': '办公楼楼梯宽度高度?', 'summarize': '民用建筑规范'},
            {'query': '无障碍卫生间的尺寸要求?', 'summarize': '无障碍设计规范'},
        ],
    },
    isUserInfoModalOpen: false,
    isFeedbackModalOpen: false,
    isFeedbackDoneModalOpen: false,
    feedback_score: 0,
    feedback_content: '',
    feedback_face_list: [
        { name: 'wearyFace', score: 1 },
        { name: 'frowningFace', score: 2 },
        { name: 'smilingFace', score: 3 },
        { name: 'grinningFace', score: 4 },
        { name: 'heartEyesFace', score: 5 },
    ],
    language: 'chinese',
    theme: 'daytime',       // daytime darknight
    avatar_upload_filelist: [],

    is_zoom: false,
    zoom_butn_text: '关闭侧边栏',

    is_show_intro_video: false,
    is_show_guide: false,
    is_show_image_mover: false,
    is_show_block_usage: false,
    is_show_user_research: false,
    user_research_data: {
        step: 1,    // 1 2 3 4
        gender: '',
        age: '',
        age_options: [
            {
                value: '18-24',
                label: '18-24',
            },
            {
                value: '25-34',
                label: '25-34',
            },
            {
                value: '35-44',
                label: '35-44',
            },
            {
                value: '45-54',
                label: '45-54',
            },
            {
                value: '55-64',
                label: '55-64',
            },
            {
                value: '65岁以上',
                label: '65岁以上',
            },
        ],
        occupation: '',
        occupation_options: [
            {
                value: '学生',
                label: '学生',
            },
            {
                value: '室内设计师',
                label: '室内设计师',
            },
            {
                value: '建筑设计师',
                label: '建筑设计师',
            },
            {
                value: '自由职业者',
                label: '自由职业者',
            },
            {
                value: '个人爱好者',
                label: '个人爱好者',
            },
            {
                value: '其他',
                label: '其他',
            },
        ],
        city: '',
        city_options: [
            { value: '北京', label: '北京', },
            { value: '上海', label: '上海', },
            { value: '广州', label: '广州', },
            { value: '深圳', label: '深圳', },
            { value: '成都', label: '成都', },
            { value: '武汉', label: '武汉', },
            { value: '西安', label: '西安', },
            { value: '大连', label: '大连', },
            { value: '海口', label: '海口', },
        ],
    },
    menu_search_content: '',

    // 图片展示相关
    is_message_image_visible: false,
    message_item_image_path: '',

    // 专家助手相关
    is_show_expert_select: true,
    is_show_expert_detail: false,
    expert_category_list: [
        // { label: '所有', value: 'all', active: true, },
        // { label: '灵石模型', value: 'line_stone_model', active: false },
        // { label: '辅助模型', value: 'auxiliary_model', active: false },
        // { label: '学科模型', value: 'discipline_model', active: false },
        // { label: '业务模型', value: 'business_model', active: false },
    ],

    // 聊天信息相关
    suggestion_list: [
        // {
        //     author: "bot",
        //     createdAt: "",
        //     messageType: "Suggestion",
        //     text: "你能聊天以外的事情吗",
        //     timestamp: "",
        // },
    ],
    allow_program_scroll: true,

    // 用户登录信息
    nickname: '',

    isUpdateInfoModalOpen: false,
    updateInfo: {
        // has_update: false,
        // release: {
        //     content: "1.0.0",
        //     id: 1,
        //     published_at: "2024-07-09 17:35:03",
        //     release_v: "1.0.0",
        //     meta_data: {
        //         images: [],
        //     },
        // },
    },

    // 自定义知识库相关
    knowledge_base_menu: [],

    // 经验值相关
    isModalExperienceOpen: false,
    current_experience: 0,
    levelThresholds: [],
                  // [50, 150, 450, 1350, 4000, 12000, 36000]
                  // [Lv1, Lv2, Lv3, Lv4, Lv5, Lv6, Lv7]
})

const reducer = (state=defaultState, action) => {
    switch(action.type) {
        case actionTypes.SET_PROMPT_ACTION:
            return state.set('prompt', action.value)
        case actionTypes.IS_THINKING_ACTION:
            return state.set('is_thinking', action.status)
        case actionTypes.IS_STOP_FETCH_RESPONDING_ACTION:
            return state.set('is_stop_fetch_responding', action.status)
        case actionTypes.ALL_CHAT_DATA_ACTION:
            return state.merge({
                'all_chat_data': fromJS(action.data),
            })
        case actionTypes.ACTIVE_TOPICID_ACTION:
            return state.merge({
                active_date: action.active_date,
                active_topicid: action.active_topicid,
            })
        case actionTypes.CHAT_TYPE_ACTION:
            return state.set('chat_type', action.value)
        case actionTypes.IS_ZOOM_ACTION:
            return state.set('is_zoom', action.status)
        case actionTypes.IS_USERINFO_MODAL_OPEN_ACTION:
            return state.set('isUserInfoModalOpen', action.status)
        case actionTypes.IS_FEEDBACK_MODAL_OPEN_ACTION:
            return state.set('isFeedbackModalOpen', action.status)
        case actionTypes.FEEDBACK_SCORE_ACTION:
            return state.set('feedback_score', action.value)
        case actionTypes.FEEDBACK_CONTENT_ACTION:
            return state.set('feedback_content', action.value)
        case actionTypes.LANGUAGE_ACTION:
            return state.set('language', action.value)
        case actionTypes.THEME_ACTION:
            return state.set('theme', action.value)
        case actionTypes.AVATAR_UPLOAD_FILELIST_ACTION:
            return state.merge({
                'avatar_upload_filelist': fromJS(action.data),
            })
        case actionTypes.ZOOM_BUTN_TEXT_ACTION:
            return state.set('zoom_butn_text', action.value)
        case actionTypes.IS_SHOW_INTRO_VIDEO_ACTION:
            return state.set('is_show_intro_video', action.status)
        case actionTypes.IS_SHOW_GUIDE_ACTION:
            return state.set('is_show_guide', action.status)
        case actionTypes.IS_SHOW_IMAGE_MOVER_ACTION:
            return state.set('is_show_image_mover', action.status)
        case actionTypes.IS_SHOW_BLOCK_USAGE_ACTION:
            return state.set('is_show_block_usage', action.status)
        case actionTypes.IS_SHOW_USER_RESEARCH_ACTION:
            return state.set('is_show_user_research', action.status)
        case actionTypes.USER_RESEARCH_DATA_ACTION:
            return state.merge({
                'user_research_data': fromJS(action.data),
            })
        case actionTypes.IS_FEEDBACK_DONE_MODAL_OPEN_ACTION:
            return state.set('isFeedbackDoneModalOpen', action.status)
        case actionTypes.MENU_SEARCH_CONTENT_ACTION:
            return state.set('menu_search_content', action.value)
        case actionTypes.MESSAGE_IMAGE_VISIABLE_ACTION:
            return state.set('is_message_image_visible', action.status)
        case actionTypes.MESSAGE_ITEM_IMAGE_PATH_ACTION:
            return state.set('message_item_image_path', action.value)
        case actionTypes.CHAT_TYPE_OPTIONS_ACTION:
            return state.merge({
                'chat_type_options': fromJS(action.data),
            })
        case actionTypes.IS_SHOW_EXPERT_SELECT_ACTION:
            return state.set('is_show_expert_select', action.status)
        case actionTypes.IS_SHOW_EXPERT_DETAIL_ACTION:
            return state.set('is_show_expert_detail', action.status)
        case actionTypes.EXPERT_CATEGORY_LIST_ACTION:
            return state.merge({
                'expert_category_list': fromJS(action.data),
            })
        case actionTypes.SUGGESTION_LIST_ACTION:
            return state.merge({
                'suggestion_list': fromJS(action.data),
            })
        case actionTypes.ALLOW_PROGRAM_SCROLL_ACTION:
            return state.set('allow_program_scroll', action.status)
        case actionTypes.NICKNAME_ACTION:
            return state.set('nickname', action.value)
        case actionTypes.RESET_STATE:
            return defaultState
        case actionTypes.THINKING_TOPIC_MESSAGEID_ACTION:
            return state.set('thinking_topic_messageid', action.value)
        case actionTypes.IS_UPDATE_INFO_MODAL_OPEN_ACTION:
            return state.set('isUpdateInfoModalOpen', action.status)
        case actionTypes.SET_UPDATE_INFO_ACTION:
            return state.merge({
                'updateInfo': fromJS(action.data),
            })
        case actionTypes.KNOWLEDGE_BASE_LIST_ACTION:
            return state.merge({
                'knowledge_base_menu': fromJS(action.data),
            })
        case actionTypes.SET_IS_MODAL_EXPERIENCE_OPEN:
            return state.set('isModalExperienceOpen', action.status)
        case actionTypes.SET_CURRENT_EXPERIENCE:
            return state.set('current_experience', action.value)
        case actionTypes.SET_LEVEL_THRESHOLDS:
            return state.merge({
                'levelThresholds': fromJS(action.data),
            })
        default:
            return state
    }
}

export default reducer