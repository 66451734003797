import React, { useState, useEffect } from 'react';
import { DatePicker, Button } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'

import { actionCreators } from '../../store'
import styles from './index.module.css'


const ConversationCountDatePicker = ({handleRefresh}) => {
    const dispatch = useDispatch()
    let filter_dates = useSelector(state => state.get('admin').get('filter_dates')).toJS()
    const [creating, setCreating] = useState(false)

    const onChange = (d, dateStrings) => {
        // d 是 moment 对象数组，dateStrings 是字符串数组
        console.log('Selected Dates: ', d);
        console.log('Formatted Selected Dates: ', dateStrings);
        dispatch(actionCreators.setFilterDates(dateStrings))
    };

    const handleSubmit = () => {
        console.log("filter_dates = ", filter_dates);
        let body = {}

        if (filter_dates.length !== 0) {
            let start_time = filter_dates[0]
            let end_time = filter_dates[1]
            body['start_time'] = start_time
            body['end_time'] = end_time
        }

        setCreating(true)
        dispatch(actionCreators.fetchConversationCountInfo(body))
            .then(data => { handleRefresh() })
            .catch(error => {})
            .finally(() => { setCreating(false)} )
    }

    return (
        <div className={styles.main}>
            <DatePicker.RangePicker
                onChange={onChange}
                showTime
            />

            {
                creating ? (
                    <Button type="primary" loading>
                        查询中...
                    </Button>
                ) : (
                    <Button type="primary" onClick={handleSubmit}>
                        查询
                    </Button>
                )
            }
        </div>
    )
}

export default ConversationCountDatePicker