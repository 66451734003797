export const SET_PROMPT_ACTION = 'home/SET_PROMPT_ACTION'
export const IS_THINKING_ACTION = 'home/IS_THINKING_ACTION'
export const IS_STOP_FETCH_RESPONDING_ACTION = 'home/IS_STOP_FETCH_RESPONDING_ACTION'
export const ALL_CHAT_DATA_ACTION = 'home/ALL_CHAT_DATA_ACTION'
export const ACTIVE_TOPICID_ACTION = 'home/ACTIVE_TOPICID_ACTION'
export const ADD_NEW_CONVERSATION_ACTION = 'home/ADD_NEW_CONVERSATION_ACTION'
export const CHAT_TYPE_ACTION = 'home/CHAT_TYPE_ACTION'
export const IS_ZOOM_ACTION = 'home/IS_ZOOM_ACTION'
export const IS_USERINFO_MODAL_OPEN_ACTION = 'home/IS_USERINFO_MODAL_OPEN_ACTION'
export const IS_FEEDBACK_MODAL_OPEN_ACTION = 'home/IS_FEEDBACK_MODAL_OPEN_ACTION'
export const FEEDBACK_SCORE_ACTION = 'home/FEEDBACK_SCORE_ACTION'
export const FEEDBACK_CONTENT_ACTION = 'home/FEEDBACK_CONTENT_ACTION'
export const LANGUAGE_ACTION = 'home/LANGUAGE_ACTION'
export const THEME_ACTION = 'home/THEME_ACTION'
export const AVATAR_UPLOAD_FILELIST_ACTION = 'home/AVATAR_UPLOAD_FILELIST_ACTION'
export const ZOOM_BUTN_TEXT_ACTION = 'home/ZOOM_BUTN_TEXT_ACTION'
export const IS_SHOW_INTRO_VIDEO_ACTION = 'home/IS_SHOW_INTRO_VIDEO_ACTION'
export const IS_SHOW_GUIDE_ACTION = 'home/IS_SHOW_GUIDE_ACTION'
export const IS_SHOW_IMAGE_MOVER_ACTION = 'home/IS_SHOW_IMAGE_MOVER_ACTION'
export const IS_SHOW_BLOCK_USAGE_ACTION = 'home/IS_SHOW_BLOCK_USAGE_ACTION'
export const IS_SHOW_USER_RESEARCH_ACTION = 'home/IS_SHOW_USER_RESEARCH_ACTION'
export const USER_RESEARCH_DATA_ACTION = 'home/USER_RESEARCH_DATA_ACTION'
export const IS_FEEDBACK_DONE_MODAL_OPEN_ACTION = 'home/IS_FEEDBACK_DONE_MODAL_OPEN_ACTION'
export const MENU_SEARCH_CONTENT_ACTION = 'home/MENU_SEARCH_CONTENT_ACTION'
export const MESSAGE_IMAGE_VISIABLE_ACTION = 'home/MESSAGE_IMAGE_VISIABLE_ACTION'
export const MESSAGE_ITEM_IMAGE_PATH_ACTION = 'home/MESSAGE_ITEM_IMAGE_PATH_ACTION'
export const CHAT_TYPE_OPTIONS_ACTION = 'home/CHAT_TYPE_OPTIONS_ACTION'
export const IS_SHOW_EXPERT_SELECT_ACTION = 'home/IS_SHOW_EXPERT_SELECT_ACTION'
export const IS_SHOW_EXPERT_DETAIL_ACTION = 'home/IS_SHOW_EXPERT_DETAIL_ACTION'
export const EXPERT_CATEGORY_LIST_ACTION = 'home/EXPERT_CATEGORY_LIST_ACTION'
export const SUGGESTION_LIST_ACTION = 'home/SUGGESTION_LIST_ACTION'
export const ALLOW_PROGRAM_SCROLL_ACTION = 'home/ALLOW_PROGRAM_SCROLL_ACTION'
export const NICKNAME_ACTION = 'home/NICKNAME_ACTION'
export const RESET_STATE = 'home/RESET_STATE'
export const THINKING_TOPIC_MESSAGEID_ACTION = 'home/THINKING_TOPIC_MESSAGEID_ACTION'
export const IS_UPDATE_INFO_MODAL_OPEN_ACTION = 'home/IS_UPDATE_INFO_MODAL_OPEN_ACTION'
export const KNOWLEDGE_BASE_LIST_ACTION = 'home/KNOWLEDGE_BASE_LIST_ACTION'
export const SET_UPDATE_INFO_ACTION = 'home/SET_UPDATE_INFO_ACTION'
export const SET_IS_MODAL_EXPERIENCE_OPEN = 'home/SET_IS_MODAL_EXPERIENCE_OPEN'
export const SET_CURRENT_EXPERIENCE = 'home/SET_CURRENT_EXPERIENCE'
export const SET_LEVEL_THRESHOLDS = 'home/SET_LEVEL_THRESHOLDS'