import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Input, Button } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import sendImg from '../../../../statics/send.png'
import loadingImg from '../../../../statics/loading.png'
import sendActiveImg from '../../../../statics/send_active.png'
import thingkingLoadingImg from '../../../../statics/thinking_loading.png'


class MessageInput extends Component {
    constructor(props) {
        super(props)

        this.textAreaRef = React.createRef()
    }

    componentDidMount() {
        this.textAreaRef.current && this.textAreaRef.current.focus()
    }

    componentDidUpdate(prevProps) {
        // 检查 is_thinking 是否从 true 变为 false
        if (prevProps.is_thinking && !this.props.is_thinking) {
            // 如果 textAreaRef.current 不为空，则调用 focus 方法
            this.textAreaRef.current && this.textAreaRef.current.focus();
        }
    }

    render() {
        let suggestion_list_temp = this.props.suggestion_list.toJS()
        let suggestion_list = suggestion_list_temp.slice(0, 3)
        // 计算 className
        let suggestion_item_className;
        if (suggestion_list.length === 1) {
            suggestion_item_className = styles.item1;
        } else if (suggestion_list.length === 2) {
            suggestion_item_className = styles.item2;
        } else {
            // suggestion_list.length === 3
            suggestion_item_className = styles.item3;
        }

        return (
            <div className={styles.container}>
                <div className={styles.textarea_box}>
                    <Input.TextArea
                        value={this.props.prompt}
                        size="large"
                        placeholder={this.props.is_thinking ? "正在思考中..." : "发送消息给灵石..."}
                        autoSize={{
                            maxRows: 10
                        }}
                        onPressEnter={this.pressEnter.bind(this)}
                        onChange={this.onChange.bind(this)}
                        // disabled={this.props.is_thinking ? true : false}
                        ref={this.textAreaRef}
                        className={styles.custom_textarea}
                    />
                        {/* <img
                            src={loadingImg}
                            className={styles.loading_img}
                        /> */}

                    {
                        this.props.is_thinking ? (
                            // <img
                            //     src={loadingImg}
                            //     className={styles.loading_img}
                            // />
                            <div
                                className={styles.thinking_loading_box}
                                onClick={this.handleStopResponding.bind(this)}
                            >
                                <img
                                    src={thingkingLoadingImg}
                                    className={styles.circle_img}
                                />
                                <div className={styles.square_img}></div>
                            </div>
                        ) : (
                            this.props.prompt.trim() !== '' && !this.props.is_thinking ? (
                                <img
                                    src={sendActiveImg}
                                    className={styles.send_butn_img}
                                    onClick={this.onSubmit.bind(this)}
                                    alt="发送"
                                />
                            ) : (
                                <img
                                    src={sendImg}
                                    className={styles.send_butn_img}
                                    // disabled={
                                        // this.props.prompt.trim() !== '' && !this.props.is_thinking ? false : true
                                    // }
                                />
                            )
                        )
                    }
                </div>

                <div className={styles.textarea_explain}>灵石LinkStone可能会犯错，请考虑核实重要信息。</div>

                {
                    this.props.is_thinking || suggestion_list.length === 0 ? null : (
                        <div className={styles.suggestion_box}>
                            <div className={styles.suggestion_title}>猜你也许想问：</div>
                            <div className={styles.suggestion_item_box}>
                                {
                                    suggestion_list.map((item, index) => {
                                        return (
                                            <div
                                                className={`${styles.item} ${suggestion_item_className}`}
                                                title={item.text}
                                                key={index}
                                                onClick={() => { this.onSuggestionClick(item.text) }}
                                            >
                                                <div className={styles.item_text}>{item.text} →</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    handleStopResponding() {
        this.props.handleStopFetchResponding()
        this.props.handleIsStopFetchResponding(true)
        setTimeout(() => {
            console.log("test");
            this.props.handleIsStopFetchResponding(false)
        }, 2000); // 持续时间要比Message组件内部的定时器长一点确保它在动画完成后再消失
    }

    pressEnter(e) {
        if (e.key === 'Enter' && e.keyCode === 13 && e.shiftKey) {
            // === 无操作 ===
        } else if (e.key === 'Enter' && e.keyCode === 13) {
            this.onSubmit()
            e.preventDefault() //禁止回车的默认换行
        }
    }

    onChange(e) {
        let value = e.target.value
        this.props.handleSetPromptAction(value)
    }

    onSubmit() {
        if (this.props.is_thinking || this.props.prompt.trim() === '') {
            return
        }

        this.props.handleSendAskAction(this.props.prompt)
    }

    onSuggestionClick(text) {
        if (text.trim() !== '') {
            this.props.handleSetPromptAction(text)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        prompt: state.get('home').get('prompt'),
        is_thinking: state.get('home').get('is_thinking'),
        all_chat_data: state.get('home').get('all_chat_data'),
        suggestion_list: state.get('home').get('suggestion_list'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSetPromptAction(data) {
            dispatch(actionCreators.setPromptAction(data))
        },
        handleSendAskAction(value) {
            dispatch(actionCreators.sendAskAction(value))
        },
        handleStopFetchResponding() {
            dispatch(actionCreators.stopFetchResponding())
        },
        handleIsStopFetchResponding(status) {
            dispatch(actionCreators.isStopFetchResponding(status))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput)