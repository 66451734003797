import React, { Component, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Form, message, Checkbox } from 'antd';

import styles from './index.module.css'
import { actionCreators } from '../../store';

function KnowledgeBaseEditModal({isModalOpen, handleCloseModal}) {
    const dispatch = useDispatch()
    const [creating, setCreating] = useState(false)
    const [isOverride, setIsOverride] = useState(false)
    const [form] = Form.useForm();
    let knowledge_base_list = useSelector(state => state.get('admin').get('knowledge_base_list'))
    let edit_knowledge_base_name = useSelector(state => state.get('admin').get('edit_knowledge_base_name'))

    useEffect(() => {
        if (isModalOpen) {
            let list = knowledge_base_list.toJS()
            let obj = list.find(item => item.name === edit_knowledge_base_name) || {}
            console.log("knowledge_base_list = ", list);
            console.log("obj = ", obj);
            console.log("useMemo edit_knowledge_base_name = ", edit_knowledge_base_name);
            setIsOverride(obj.is_active || false)
            form.setFieldsValue({
                model_name: edit_knowledge_base_name,
                new_model_name: edit_knowledge_base_name,
                overview: obj.overview || '',
                description: obj.description || '',
            })
        }
    }, [isModalOpen, knowledge_base_list, edit_knowledge_base_name, form])

    const onFinish = (values) => {
        console.log('Success:', values);
        let new_model_name = values.new_model_name.trim()
        let overview = values.overview.trim()
        let description = values.description.trim()
        if (new_model_name !== edit_knowledge_base_name) {
            console.log("new_model_name = ", new_model_name);
            console.log("edit_knowledge_base_name = ", edit_knowledge_base_name);
            let list = knowledge_base_list.toJS()
            let obj = list.find(item => item.name === new_model_name)
            if (obj) {
                message.info("不能跟已存在的知识库同名")
                return;
            }
        }

        if (overview === '') {
            message.info("模型描述信息不能为空")
        } else if (description === '') {
            message.info("给后端模型使用的描述信息不能为空")
        } else {
            setCreating(true)
            dispatch(actionCreators.updateKnowledgeBase(edit_knowledge_base_name, new_model_name, overview, description, isOverride))
                .then(data => { handleCloseModalLocal() })
                .catch(error => {})
                .finally(() => {setCreating(false)})
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleCloseModalLocal = () => {
        form.resetFields();
        handleCloseModal()
    }

    return (
        <>
            <Modal
                title="编辑知识库"
                open={isModalOpen}
                onOk={handleCloseModalLocal}
                onCancel={handleCloseModalLocal}
                // okText="确认"
                // cancelText="取消"
                footer={false}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    preserve={false}
                    form={form}
                    // initialValues={{
                    //     model_name: edit_knowledge_base_name,
                    //     new_model_name: edit_knowledge_base_name,
                    //     overview: current_knowledge_base_obj.overview || '',
                    //     description: current_knowledge_base_obj.description || '',
                    // }}
                >
                    <Form.Item
                        label="知识库名称"
                        name="model_name"
                    >
                        <span>{ edit_knowledge_base_name }</span>
                    </Form.Item>

                    <Form.Item
                        label="新的知识库名称"
                        name="new_model_name"
                        rules={[
                            {
                                required: true,
                                message: '请输入新的知识库名称',
                            },
                        ]}
                    >
                        <Input placeholder="请输入新的知识库名称" />
                    </Form.Item>

                    <Form.Item
                        label="模型的展示描述"
                        name="overview"
                        rules={[{ required: true, message: '请输入模型的展示描述' }]}
                    >
                        <Input.TextArea
                            // showCount
                            // maxLength={100}
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 100,
                            }}
                            onChange={(e) => {console.log('Change:', e.target.value)}}
                            placeholder="展示给用户的模型描述信息"
                        />
                    </Form.Item>

                    <Form.Item
                        label="模型的代理描述"
                        name="description"
                        rules={[{ required: true, message: '请输入给后端代理模型的描述信息' }]}
                    >
                        <Input.TextArea
                            // showCount
                            // maxLength={100}
                            style={{
                                resize: 'none',
                                width: '100%',
                                height: 100,
                            }}
                            onChange={(e) => {console.log('Change:', e.target.value)}}
                            placeholder="给后端代理模型的描述信息（灵石据此选择合适的专家模型）"
                        />
                    </Form.Item>

                    <Form.Item
                        label="是否覆盖同名百炼专家"
                        name="override"
                    >
                        <Checkbox
                            checked={isOverride}
                            onChange={() => {setIsOverride(!isOverride)}}
                        >
                            {/* tips：同名模型支持覆盖 */}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        {
                            creating ? (
                                <Button type="primary" loading>
                                    保存中
                                </Button>
                            ) : (
                                <Button type="primary" htmlType="submit">
                                    保存
                                </Button>
                            )
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default KnowledgeBaseEditModal