import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Divider } from 'antd'

import styles from './index.module.css'
import { actionCreators } from '../../store'
import nlpImg from '../../../../statics/none_info_nlp.png'
import learningImg from '../../../../statics/none_info_learning.png'
import securityImg from '../../../../statics/none_info_security.png'
import contrastImg from '../../../../statics/message_none_contrast.png'
import flashImg from '../../../../statics/message_none_flash.png'


class MessageNone extends Component {
    render() {
        let chat_type = this.props.chat_type
        let chat_type_options = this.props.chat_type_options.toJS()
        let chat_type_obj = chat_type_options.find(option => option.value === chat_type) || {}
        let icon = chat_type_obj.avatar
        let temp_list = this.props.chat_type_show_list.toJS()

        return (
            <div className={styles.container}>
                <div className={styles.expert_box}>
                    <img src={icon} className={`${chat_type === 'link_stone' ? styles.bg_agent : styles.bg}`} />
                    <div className={styles.title_llm}>{ chat_type_obj.label }</div>
                    <div className={styles.explain_llm}>{ chat_type_obj.info }</div>
                </div>

                {
                    this.props.chat_type === 'bailian_chat' || this.props.chat_type === 'tiangong_search' || this.props.chat_type === 'llm_chat' ? (
                        <>
                            <div className={styles.divider_box}>
                            {/* <Divider style={{margin: 0, backgroundColor: "#cacfdb"}} /> */}
                            </div>

                            <div className={styles.bottom_box}>
                                <div className={styles.item}>
                                    <div className={styles.img_text_box}>
                                        <img src={flashImg} className={styles.img} />
                                        <span className={styles.text}>案例</span>
                                    </div>
                                    <div className={styles.img_text_box}>
                                        <img src={contrastImg} className={styles.img} />
                                        <span className={styles.text}>猜你需要</span>
                                    </div>
                                </div>

                                {
                                    temp_list[this.props.chat_type].map((item) => {
                                        return (
                                            <div className={styles.item}>
                                                <div
                                                    className={styles.rect_box}
                                                    onClick={() => {this.onSubmit(item.query)}}
                                                >
                                                    {item.query}
                                                </div>
                                                <div
                                                    className={styles.rect_box}
                                                    onClick={() => {this.onSubmit(item.query)}}
                                                >
                                                    {item.summarize}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.divider_box}>
                                <Divider style={{margin: 0, backgroundColor: "#cacfdb"}} />
                            </div>

                            <div className={styles.general_box}>
                                <div className={styles.info_item}>
                                    <div className={styles.info_item_header}>
                                        <img src={nlpImg} className={styles.info_item_img} />
                                        <div className={styles.info_item_title}>自然语言处理(NLP)</div>
                                    </div>
                                    <div className={styles.info_item_explain}>
                                        灵石将从知识库中翻阅并回答你想要了解的专业知识，像与人聊天一样自然。
                                    </div>
                                </div>
                                <div className={styles.info_item}>
                                    <div className={styles.info_item_header}>
                                        <img src={learningImg} className={styles.info_item_img} />
                                        <div className={styles.info_item_title}>不间断地学习</div>
                                    </div>
                                    <div className={styles.info_item_explain}>
                                        通过机器学习，它可以从互动中学习，善于了解你的偏好并预测你的需求。
                                    </div>
                                </div>
                                <div className={styles.info_item}>
                                    <div className={styles.info_item_header}>
                                        <img src={securityImg} className={styles.info_item_img} />
                                        <div className={styles.info_item_title}>隐私与安全</div>
                                    </div>
                                    <div className={styles.info_item_explain}>
                                        灵石采用顶级加密技术保护你与灵石的对话，确保你的所有个人信息保密。
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        );
    }

    onSubmit(value) {
        if (this.props.is_thinking) {
            return
        }

        this.props.handleSendAskAction(value)
    }
}

const mapStateToProps = (state) => {
    return {
        chat_type: state.get('home').get('chat_type'),
        chat_type_options: state.get('home').get('chat_type_options'),
        chat_type_show_list: state.get('home').get('chat_type_show_list'),
        is_thinking: state.get('home').get('is_thinking'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleSendAskAction(value) {
            dispatch(actionCreators.sendAskAction(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageNone)