import React, { Component } from 'react';
import { connect } from 'react-redux'

import styles from './index.module.css'
import { actionCreators } from '../../store'

import moreImg from '../../../../statics/chat_type_sel_more.png'
// import bgImg from '../../../../statics/none_bg.png'
import bgImg from '../../../../statics/ai_link_stone.gif'
import { getTopActiveModelsByWeight } from '../../../../utils';


class ChatTypeSelectionScreen extends Component {
    render() {
        let chat_type_options = this.props.chat_type_options.toJS()
        let temp_chat_type_options = getTopActiveModelsByWeight(chat_type_options, 4)

        let user_data = this.props.user_data.toJS()

        return (
            <div className={styles.container}>
                <div className={`${styles.header_text_box} ${this.props.is_show_block_usage ? styles.fade_in : ''}`}>
                    <div className={styles.title}>专家助手</div>
                    <div className={styles.more_box}>
                        <div className={styles.more_text} onClick={this.handleShowMore.bind(this)}>查看更多</div>
                        <img src={moreImg} className={styles.more_img} />
                    </div>
                </div>

                <div className={`${styles.header_butn_box} ${this.props.is_show_block_usage ? styles.fade_in : ''}`}>
                    {
                        temp_chat_type_options.map((item) => {
                            let icon = item.avatar

                            if (item.active && item.value !== 'link_stone') {
                                return (
                                    <div
                                        key={item.value}
                                        className={styles.butn_box}
                                        onClick={() => {this.addNewConversation(item.value)}}
                                    >
                                        <div className={styles.butn_imgs}>
                                            <img src={icon} className={styles.expert_img} />
                                            {/* <div className={styles.bookmark_img}></div> */}
                                        </div>
                                        <div className={styles.butn_texts}>
                                            <div className={styles.butn_text_title}>{item.label}</div>
                                            <div className={styles.butn_text_info}>{item.info}</div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>

                <div className={styles.main}>
                    <img src={bgImg} className={styles.bg} />
                    <div className={`${styles.welcome_title} ${this.props.is_show_block_usage ? styles.fade_in : ''}`}>
                        Hi, {user_data.nickname ? user_data.nickname : ''}! 欢迎来到灵石
                    </div>
                    <div className={`${styles.welcome_info} ${this.props.is_show_block_usage ? styles.fade_in : ''}`}>
                        灵石欢迎你！请问有什么可以帮到你的吗？
                    </div>
                    <div
                        className={`${styles.create_butn} ${this.props.is_show_block_usage ? styles.fade_in : ''}`}
                        onClick={() => {this.addNewConversation('link_stone')}}
                    >
                        <span>创建新对话</span>
                        <div
                            className={styles.search_box_mask}
                            id="tour6"
                        >
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleShowMore() {
        this.props.handleIsShowExpertSelectAction(false)
        this.props.handleIsShowExpertDetailAction(true)
        this.props.handleActiveTopicIdAction('', '')
    }

    addNewConversation(value) {
        let chat_type_options = this.props.chat_type_options.toJS()
        let chat_type_obj = chat_type_options.find(option => option.value === value)
        // 当存在指定的模型时，才能进入对话问答画面
        if (chat_type_obj) {
            this.props.handleChatTypeChangeAction(value)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        chat_type_options: state.get('home').get('chat_type_options'),
        user_data: state.get('login').get('user_data'),
        is_show_block_usage: state.get('home').get('is_show_block_usage'),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleIsShowExpertSelectAction(status) {
            dispatch(actionCreators.isShowExpertSelectAction(status))
        },
        handleIsShowExpertDetailAction(status) {
            dispatch(actionCreators.isShowExpertDetailAction(status))
        },
        handleActiveTopicIdAction(active_date, active_topicid) {
            dispatch(actionCreators.activeTopicIdAction(active_date, active_topicid))
        },
        handleChatTypeChangeAction(value) {
            dispatch(actionCreators.chatTypeChangeAction(value))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatTypeSelectionScreen)